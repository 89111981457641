import React, { Component, useRef, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';

import { renderHeaderTop }  from './ArticleUtils.js';

import { useParams } from "react-router-dom";

import { collection, query, where, getDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

// const SAMPLE_TEXT = {
//   "items": [
//     {
//       "type": "plain",
//       "content": "My favorite singer is "
//     },
//     {
//       "type": "link",
//       "content": "Frank Sinatra",
//       "link": "https://en.wikipedia.org/wiki/Frank_Sinatra"
//     },
//     {
//       "type": "plain",
//       "content": ". He is "
//     },
//     {
//       "type": "bold",
//       "content": "great"
//     },
//     {
//       "type": "plain",
//       "content": " during the winter and he just has that "
//     },
//     {
//       "type": "italic",
//       "content": "cool sound."
//     },
//   ]
// }

// function renderParagraph() {
//   let textItems = SAMPLE_TEXT.items.map((item, index) => {
//     switch(item.type) {
//       case "plain":
//         return item.content;
//       case "bold":
//         return <strong key={index}>{ item.content }</strong>;
//       case "italic":
//         return <i key={index}>{ item.content }</i>;
//       case "link":
//         return <a key={index} href={ item.link }>{ item.content }</a>;
//     }
//   })
//   return (
//     <div className="article-paragraph"><p>
//       { textItems.map(item => {
//         return item;
//       }) }
//     </p></div>
//   );
// }

const Article = () => {
  const routeParams = useParams();
  const article_id = routeParams.id;

  const [article, setArticle] = useState({});
  const [articleReady, setArticleReady] = useState(false);
  const [author, setAuthor] = useState({});

  useEffect(() => {
    getArticle(article_id);
  }, [])

  const getArticle = (article_id) => {

    const articleRef = doc(db, 'articles', article_id);

    getDoc(articleRef).then((doc) => {
      const data = doc.data();
      console.log(data);
      setArticle(data)
      setAuthor({
        bio: data.author_bio,
        name: data.author_name,
        image_url: data.author_image_url,
        profile_url: data.author_profile_url,
        size: data.author_size
      });
      setArticleReady(true)
    })
  }



  const renderHeaderBottom = () => {
    let size = author.size;

    return (
      <div className={"article-header-bottom article-header-bottom-" + size }>
        <div className={"article-author article-author-" + size }>
          { size != 'sm'
            ? <img className={"author-image author-image-" + size } src={ author.image_url }/>
            : null
          }
          <div className="author-metadata">
            <p className={"author-title author-title-" + size } ><span>By</span> <span className="author-name"><a href={ author.profile_url }>{ author.name }</a></span></p>
            { size == 'lg'
              ? <p className="author-bio">{ author.bio }</p>
              : null
            }
          </div>
        </div>
        { size != 'lg'
          ? <p className={"article-date article-date-" + size }>{ article.date_pulished }</p>
          : null
        }
      </div>
    );
  }

  if (articleReady == false) {
    return (
      <div className="Article">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Article">
        <NavBarMini alwaysShown={true} />
        <div className="article-content">
          <div className="article-header">
            { renderHeaderTop(article) }
            { renderHeaderBottom() }
          </div>

          <div className="article-body">
            { article.content?.map((paragraph, index) => {
            return (
              <div key={"paragraph-" + index} className="article-paragraph">
                <p>{ paragraph }</p>
              </div>
            )})}
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default Article;
