import React, { Component } from 'react';

function getDate() {
  const today = new Date();
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const month = ['January', 'February', 'March', 'April',  'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var day = today.getDay();
  var m = today.getMonth();
  var d = String(today.getDate());
  var y = today.getFullYear();
  return weekday[day] + ', ' + month[m] + ' ' + d + ', ' + y;
}

function getTime() {
  const today = new Date();
  var hour = String(today.getHours()).padStart(2, '0');
  var minutes = String(today.getMinutes()).padStart(2, '0');
  var seconds = String(today.getSeconds()).padStart(2, '0');
  var label;

  if(hour > 11) {
    label = 'P.M.'
    if(hour > 12) {
      hour -= 12
    }
  } else {
    label = 'A.M.'
    if(hour == 0) {
      hour = 12
    }
  }

  // d, MM DD, YYYY H:M:S
  //return hour + ':' + minutes + ':' + seconds + ' ' + label;

  // d, MM DD, YYYY H:M
  // return hour + ':' + minutes + ' ' + label;

  // d, MM DD, YYYY
  return;
}

class Datetime extends Component {
  constructor(props){
    super(props);
    this.state = {
      date: getDate(),
      time: getTime()
    };
  }

  render(){
    return(
      <div className="line-height-sm">
        <span className="nav-text equal-width-numbers">
          <b>{this.state.date}</b>
          <br/>
          <b>{this.state.time}</b>
        </span>
      </div>
    );
  }
  componentDidMount() {
    this.interval = setInterval(() => this.setState({date: getDate(), time: getTime()}), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
}

export default Datetime;
