import React, { Component, useRef, useState, useEffect } from 'react';

import LoadingAnimation from '../LoadingAnimation.js';
import * as MastersUtils from './MastersUtils.js';
import NavBarMini from '../NavBarMini.js';
import Footer from '../Footer.js';

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.js';

const currentYear = '2024'
const currentYearData = 'data2024'

const defaultMastersData = {
  currentRound: '1000',
  statusRound: 'NNNNN',
  wallClockTime: '0',
  player: [],
  yardages: [],
  pars: []
}

const MastersAugustaRefactor = () => {
  const [leaderboardReady, setLeaderboardReady] = useState(false);
  const [masters, setMasters] = useState(defaultMastersData);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
    getMastersData();

    setLeaderboardReady(true);
  }, [])

  // Fetch users from Firebase DB
  const getUsers = async () => {
    const docs = await getDocs(collection(db, "masters", currentYearData, "users"));

    let users = [];
    docs.forEach((doc) => {
      const userID = doc.id;
      const user = doc.data();
      user.id = userID;
      users.push(user);
    });

    setUsers(users);
  }

  // Fetch Masters data from API endpoint
  const getMastersData = async () => {
    fetch(`https://www.masters.com/en_US/scores/feeds/${currentYear}/scores.json`)
      .then((response) => response.json())
      .then((data) => {
        setMasters(data.data);
      })
  }

  function displayPlayerTableRows () {
    if (MastersUtils.tournamentStarted()) {
      let sortedPlayers = MastersUtils.sortPlayers(masters);

      let playerElements = sortedPlayers.map((player, place) => displayPlayerRow(player, place) )

      return playerElements;
    } else {
    }
  }

  function displayPlayerRow (player, place) {
    let roundNumber = MastersUtils.getRoundNumber(masters)

    let rowClassList = [];
    if (MastersUtils.tournamentEnded(masters) && place === 0) {
      rowClassList.push('winner');
    }
    return (
        <tr className={ rowClassList.join(' ') }>
          <td className="masters-table-data-name">{ player.display_name.toUpperCase() }</td>
          { MastersUtils.playerRoundScores(player, roundNumber, masters.pars.round1) }
          { MastersUtils.playerGrossScore(player) }
          { MastersUtils.playerThru(player) }
        </tr>
    );
  }

  if (leaderboardReady == false) {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <div className="masters-content">
          <div className="masters-header">
            <div className="masters-header-top">
              <a className="masters-type" href="https://www.masters.com/">The Masters</a>
              <p className={ "masters-title-standard" }>{ currentYear } 1A Masters Pool</p>
              <a className="masters-type" href="/masters">Pool Leaders</a>
            </div>
          </div>


          <div className="masters-body">
            <div className="masters-table-container">
              <div className="cut-off-half"><div className="masters-table-heading-title"><span>LEADERS</span></div></div>

              <table className="table masters-table">
                <thead className="masters-table-heading-hole">
                  <tr>
                    <th className="masters-table-heading-name"><span>HOLE</span></th>
                    <th className="masters-table-heading hole"><span>1</span></th>
                    <th className="masters-table-heading hole"><span>2</span></th>
                    <th className="masters-table-heading hole"><span>3</span></th>
                    <th className="masters-table-heading hole"><span>4</span></th>
                    <th className="masters-table-heading hole"><span>5</span></th>
                    <th className="masters-table-heading hole"><span>6</span></th>
                    <th className="masters-table-heading hole"><span>7</span></th>
                    <th className="masters-table-heading hole"><span>8</span></th>
                    <th className="masters-table-heading hole"><span>9</span></th>
                    <th className="masters-table-heading hole"><span>10</span></th>
                    <th className="masters-table-heading hole"><span>11</span></th>
                    <th className="masters-table-heading hole"><span>12</span></th>
                    <th className="masters-table-heading hole"><span>13</span></th>
                    <th className="masters-table-heading hole"><span>14</span></th>
                    <th className="masters-table-heading hole"><span>15</span></th>
                    <th className="masters-table-heading hole"><span>16</span></th>
                    <th className="masters-table-heading hole"><span>17</span></th>
                    <th className="masters-table-heading hole"><span>18</span></th>
                  </tr>
                </thead>
                <thead className="masters-table-heading-par">
                <tr>
                  <th className="masters-table-heading-name"><span>PAR</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                </tr>
                </thead>
                <thead className="masters-table-heading-total">
                <tr>
                <th className="masters-table-heading-name"><span>NAME</span></th>
                <th className="masters-table-heading"><span>TOTAL</span></th>
                <th className="masters-table-heading"><span>THRU</span></th>
                </tr>
                </thead>
                <tbody>
                  { displayPlayerTableRows() }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default MastersAugustaRefactor;
