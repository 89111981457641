import { collection, query, where, getDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const getDateByTimestamp = (timestamp) => {
  console.log(timestamp)
  const datetime = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
  );
  const month = datetime.toLocaleString('default', { month: 'short' });
  const day = datetime.toLocaleString('default', { day: 'numeric' });
  const year = datetime.toLocaleString('default', { year: 'numeric' });

  return `${month}. ${day}, ${year}`; // e.g. Jan. 16, 2023
}

export const renderHeaderTop = (article, mode='production') => {
  let dateSize;
  let imageSize;
  console.log('here')
  console.log(mode, 'mode')
  console.log(article)
  // console.log(article.date_published)
  // console.log(getDateByTimestamp(article.date_published))

  switch(article.style) {
    case "standard":
      dateSize = 'lg';
      imageSize = 'sm';
      return (
        <div className="article-header-top">
          <a className="article-type" href={ "/" + article.type }>{ article.type }</a>
          <p className="article-type-secondary">{ article.type_secondary }</p>
          <p className={ "article-title article-title-" + article.style }>{ article.title }</p>
          <p className={ "article-date article-date-" + dateSize }>{ getDateByTimestamp(article.date_published) }</p>
          <div className={ "article-image article-image-" + imageSize }>
            <img className="article-image-content" src={ article.image_link }/>
            <div className="article-image-metadata">
              <span className="article-image-description">{ article.image_desc }</span>
              <span className="article-image-credit">{ article.image_credit }</span>
            </div>
          </div>
        </div>
      );
    case "skinny":
      dateSize = 'lg';
      imageSize = 'sm';
      return (
        <div className="article-header-top">
          <a className="article-type" href={ "/" + article.type }>{ article.type }</a>
          <p className="article-type-secondary">{ article.type_secondary }</p>
          <p className={ "article-title article-title-" + article.style }>{ article.title }</p>
          <p className={ "article-date article-date-" + dateSize }>{ getDateByTimestamp(article.date_published) }</p>
          <div className={ "article-image article-image-" + imageSize }>
            <img className="article-image-content" src={ article.image_link }/>
            <div className="article-image-metadata">
              <span className="article-image-description">{ article.image_desc }</span>
              <span className="article-image-credit">{ article.image_credit }</span>
            </div>
          </div>
        </div>
      );
    case "wide":
      dateSize = 'lg';
      imageSize = 'sm';
      return (
        <div className="article-header-top">
          <p className={ "article-title article-title-" + article.style }>{ article.title }</p>
          <p className={ "article-subtitle-" + article.style }>{ article.subtitle }</p>
          <div className={ "article-image article-image-" + imageSize }>
            <img className="article-image-content" src={ article.image_link }/>
            <div className="article-image-metadata">
              <span className="article-image-description">{ article.image_desc }</span>
              <span className="article-image-credit">{ article.image_credit }</span>
            </div>
          </div>
        </div>
      );
    }
}
