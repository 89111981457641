import React, { Component } from 'react';

class Tichu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: 'chandlercrane'
    }
  }

  render(){
    return (
      <div className="Tichu">
        <div className="tichu-header">
          <h4 className="tichu-header-title">Tichu</h4>
        </div>
      </div>
    );
  }

  // componentDidMount() {
  //   this.interval = setInterval(() => {
  //     if(!this.state.paused) {
  //       let newGameboard = updateGameboard(this.state.gameboard);
  //       if(gameDone(this.state.gameboard, newGameboard)) {
  //         this.setState({ paused: true });
  //       } else {
  //         this.setState({ gameboard: newGameboard });
  //       }
  //     }
  //   }, 500);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }
}

export default Tichu;
