import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Datetime from './Datetime'
import Clock from './Clock'
import ColorPalette from './ColorPalette'
import ChangeTheme from './ChangeTheme'

import { getAuth } from "firebase/auth";

function showAccountOrLogin () {
	const user = getAuth().currentUser;

	if (user) {
    window.location.href = '/account';
	} else {
    window.location.href = '/login';
	}
}

function toggleNavButtons () {
	let navList = document.getElementById('nav-list')
	let navX = document.getElementById('nav-x')
	let navAccount = document.getElementById('nav-account')
	if (navList.style.display !== 'none') {
		navList.style.display = 'none';
		navAccount.style.display = 'none';
		navX.style.display = 'block';
	} else {
		navList.style.display = 'block';
		navAccount.style.display = 'block';
		navX.style.display = 'none';
	}
}

function toggleNavMenu () {
		// toggle side nav menu
	let navMenuClassList = document.getElementById('nav-menu').classList
	if (navMenuClassList.contains('nav-open')) {
		navMenuClassList.toggle('nav-open')
		navMenuClassList.toggle('nav-closed')
	} else if (navMenuClassList.contains('nav-closed')) {
		navMenuClassList.toggle('nav-closed')
		navMenuClassList.toggle('nav-open')
	} else {
		navMenuClassList.toggle('nav-open')
	}

	// make body non-scrollabsle
	if (document.body.style.overflow) {
		document.body.style.overflow = '';
	} else {
		document.body.style.overflow = 'hidden';
	}

	// toggle list icon and 'X' icon
	toggleNavButtons();
}

const NavBarMini = (props) => {
	let alwaysShown = props.alwaysShown;
	let page = props.page;

  return (
    <Col className={`NavBarMini ${alwaysShown ? "always-shown" : ""}`}>
      <Row className="nav-top-mini">

      </Row>

      <Row className="nav-header-mini">
        <Col id="main-nav-left">
					<button id="nav-list" onClick={toggleNavMenu} type="button">
						<svg id="nav-list-svg" viewBox="0 0 16 16"><rect x="1" y="3" fill="#333" width="14" height="2"></rect><rect x="1" y="7" fill="#333" width="14" height="2"></rect><rect x="1" y="11" fill="#333" width="14" height="2"></rect></svg>
					</button>
					<span id='nav-title'>{ page }</span>
        </Col>

        <Col xs={5} sm={6} id="main-logo-mini">
					<svg viewBox="0 0 192 192" className={ page == 'Account' ? '' : 'hidden' }>
						<g id="nameLogoSmall">
							<a href="/">
								<path d="M148.5,27.8l5.9-5.9l1.5,2.1l-22.1,27.6c-7.8-3.8-15.5-8.3-22.5-13.1c0,39.8,0,79.4,0,119.2c17.3-2.9,30-16.4,36.3-31.7h3.6c-1.4,33.2-23.4,57.2-52.1,57.2c-35.3-0.2-62.9-38.3-62.9-80.6c0-41.4,23.6-72.8,50.3-83.6l1.8,3.2c-8.1,5.1-14.9,12.6-19.7,22.2l48.6-35.7C126.4,17,136.6,23.3,148.5,27.8z M73.9,118.9c0-23.6,0-47.3,0-70.8l-9.8,7.2c-3.5,10.4-5.3,22.5-5.3,35.9c0,12,1.7,23.3,4.8,33L73.9,118.9z M104.9,158.2c0.3,0,0.8,0,1.1,0c0-41.1,0-82.2,0-123.4c-2.3-1.7-4.7-3.5-6.8-5.3l-1.5,1.1v82.8l-32,15.9C73,146.8,86,158.2,104.9,158.2z"/>
							</a>
						</g>
					</svg>
					<svg version="1.1" viewBox="0 0 839 134.4" className={ page == 'Account' ? 'hidden' : '' }>
						<style type="text/css">
						</style>
						<g id="nameLogo">
							<a href="/">
								<path d="M83.8,15.3l3.9-3.9l1,1.4L74,31.2c-5.2-2.5-10.3-5.5-15-8.7c0,26.5,0,52.9,0,79.4C70.5,100,79,91,83.2,80.8h2.4
									c-0.9,22.1-15.6,38.1-34.7,38.1C27.4,118.8,9,93.4,9,65.2C9,37.6,24.7,16.7,42.5,9.5l1.2,2.1C38.3,15,33.8,20,30.6,26.4L63,2.6
									C69.1,8.1,75.9,12.3,83.8,15.3z M34.1,76c0-15.7,0-31.5,0-47.2l-6.5,4.8c-2.3,6.9-3.5,15-3.5,23.9c0,8,1.1,15.5,3.2,22L34.1,76z
										M54.8,102.2c0.2,0,0.5,0,0.7,0c0-27.4,0-54.8,0-82.2c-1.5-1.1-3.1-2.3-4.5-3.5l-1,0.7l0,55.2L28.7,83
									C33.5,94.6,42.2,102.2,54.8,102.2z"/>
								<path d="M121.7,103.3l3.8-3.2l2.2,3.3l-15.3,12.7l-12.7-12.9l-4.7,3.5l-2.1-3.4l8.3-6.4V29.9c0-2.4,0-5.6-5.9-7.8V20l20.1-17h2.4
									v54.8L133,40.5l13,13.1l4.9-4.7l2.2,3.2l-5.3,5.2l0,42.2c0,15.2-4.3,33.5-25.7,33.5c-1.3,0-2.6-0.1-3.8-0.3V129
									c9.1,0,13.6-14.4,13.6-29.6V61.7l-7.3-7.3l-6.8,7.7v36.4L121.7,103.3z"/>
								<path d="M159.1,97.5c0-7.9,5.1-15.4,14.2-21.3c-5.7-2.9-10-7.2-10-14c0-6.5,4-10.2,7.7-12.5l13.6-8.5l18.2,14.1l5-4.8l2.2,3.2
									l-6.4,6.2v37.2l5.9,7.1l5.3-4.2l0.6,2.8L198,116.9l-9.4-11.3l-13,11.3C167.8,115.7,159.1,108.5,159.1,97.5z M166.8,61.3
									c0,9.5,13,13.4,21.2,15.7c0-4.6,0-9.2,0-13.9l-14.5-10.7C169.9,54.7,166.8,57.4,166.8,61.3z M175.5,77.3c-0.8,1.4-1.6,4-1.6,6.8
									c0,8.4,3.9,20.2,11.5,20.2c0.9,0,1.7-0.2,2.6-0.5c0-7.4,0-14.9,0-22.3C184.1,80.3,179.6,79.1,175.5,77.3z"/>
								<path d="M283.1,103.5l-18.3,14.9l-12.4-13.5l4.1-5V63.6l-8.8-9.2l-5.3,5.1v40.5l5.3,5.7l-11.8,12.7l-14.2-13.5l4.7-5V60.7l-4.7-6.4
									l-4.7,3.5l-1.8-2.8l17.1-14.2l9.5,12.7l13.5-12.7l13.9,14.4l5.2-5l2.2,3.2l-6,5.8v38.6l5.3,5.7l4.7-4.2L283.1,103.5z"/>
								<path d="M293.3,33.8l-2.5,0.5l0-26.5l42.4,44.8l4.5-4.4l2.2,3.2l-5.3,5.1l-0.1,41.9l-27,19.1l-17.8-14.6V57.5l15-11.1L293.3,33.8z
										M305.8,95.2l13,10.9l0-44.4l-13-14.3V95.2z"/>
								<path d="M373,104.2l3.5-3.5l1.8,2.8l-15.3,12.7l-13-13.4l-4.7,3.5l-1.8-2.8l8.3-6.4V32.8c0-7-0.5-8.6-5.9-10.7V20l20.1-17h2.4v95.6
									L373,104.2z"/>
								<path d="M377.8,103.5l5.8-5.7V58.9l29.5-18.4l12.4,22.7v0.7l-26.5,19.8V90l14.7,12l13.6-12.7l1.8,3.5l-25.4,24.8l-18.3-16.3
									l-5.4,5.3L377.8,103.5z M411.3,69.2l-10-17.4l-2.4,1.6v25.2L411.3,69.2z"/>
								<path d="M471,101l-18.4,15.9l-13.5-12.7l-4.1,3.5l-1.8-2.8l7.7-6.4V59.6l-4.1-6.4l-4.7,3.5l-1.8-2.8l16.5-13.4l9,11.6l8.6-11.7h1.3
									c1.2,2.2,3.3,3.6,5.6,3.6c2.5,0,4.5-1.7,5.6-3.6h3.7c-1.4,18.3-8.6,20.6-12.2,20.6c-2.7,0-7-1.5-11-6.1v43.5l4.9,4.6l6.6-5.4
									L471,101z"/>
								<path d="M596.1,15.3l3.9-3.9l1,1.4l-14.6,18.3c-5.2-2.5-10.3-5.5-15-8.7c0,26.5,0,52.9,0,79.4c11.5-1.8,20-10.9,24.2-21.1h2.4
									c-0.9,22.1-15.6,38.1-34.7,38.1c-23.5,0-41.9-25.4-41.9-53.6c0-27.5,15.7-48.4,33.6-55.7l1.2,2.1c-5.4,3.4-9.9,8.4-13.2,14.8
									l32.4-23.8C581.4,8.1,588.2,12.3,596.1,15.3z M546.4,76c0-15.7,0-31.5,0-47.2l-6.5,4.8c-2.3,6.9-3.5,15-3.5,23.9
									c0,8,1.1,15.5,3.2,22L546.4,76z M567.1,102.2c0.2,0,0.5,0,0.7,0c0-27.4,0-54.8,0-82.2c-1.5-1.1-3.1-2.3-4.5-3.5l-1,0.7l0,55.2
									L541,83C545.8,94.6,554.5,102.2,567.1,102.2z"/>
								<path d="M643.5,101l-18.4,15.9l-13.5-12.7l-4.1,3.5l-1.8-2.8l7.7-6.4V59.6l-4.1-6.4l-4.7,3.5l-1.8-2.8l16.5-13.4l9,11.6l8.6-11.7
									h1.3c1.2,2.2,3.3,3.6,5.6,3.6c2.5,0,4.5-1.7,5.6-3.6h3.7c-1.4,18.3-8.6,20.6-12.2,20.6c-2.7,0-7-1.5-11-6.1v43.5l4.9,4.6l6.6-5.4
									L643.5,101z"/>
								<path d="M656.2,97.5c0-7.9,5.1-15.4,14.2-21.3c-5.7-2.9-10-7.2-10-14c0-6.5,4-10.2,7.7-12.5l13.6-8.5l18.2,14.1l5-4.8l2.2,3.2
									l-6.4,6.2v37.2l5.9,7.1l5.3-4.2l0.6,2.8l-17.1,14.1l-9.4-11.3l-13,11.3C665,115.7,656.2,108.5,656.2,97.5z M664,61.3
									c0,9.5,13,13.4,21.2,15.7c0-4.6,0-9.2,0-13.9l-14.5-10.7C667.1,54.7,664,57.4,664,61.3z M672.7,77.3c-0.8,1.4-1.6,4-1.6,6.8
									c0,8.4,3.9,20.2,11.5,20.2c0.9,0,1.7-0.2,2.6-0.5c0-7.4,0-14.9,0-22.3C681.3,80.3,676.8,79.1,672.7,77.3z"/>
								<path d="M780.2,103.5l-18.3,14.9l-12.4-13.5l4.1-5V63.6l-8.8-9.2l-5.3,5.1v40.5l5.3,5.7L733,118.4l-14.2-13.5l4.7-5V60.7l-4.7-6.4
									l-4.7,3.5l-1.8-2.8l17.1-14.2l9.5,12.7l13.5-12.7l13.9,14.4l5.2-5l2.2,3.2l-6,5.8v38.6l5.3,5.7l4.7-4.2L780.2,103.5z"/>
								<path d="M781.2,103.5l5.8-5.7V58.9l29.5-18.4l12.4,22.7v0.7l-26.5,19.8V90l14.7,12l13.6-12.7l1.8,3.5L807,117.6l-18.3-16.3
									l-5.4,5.3L781.2,103.5z M814.7,69.2l-10-17.4l-2.4,1.6v25.2L814.7,69.2z"/>
								<rect className="transparent" width="839" height="134.4"/>
							</a>
						</g>
					</svg>
        </Col>
        <Col id="main-nav-right">
					<button id="nav-account" onClick={showAccountOrLogin} type="button">
						<a><svg id="nav-account-svg" viewBox="0 0 20 20" fill="#333" xmlns="http://www.w3.org/2000/svg"><path d="M14.2379 6C14.2379 8.20914 12.4471 10 10.2379 10C8.02878 10 6.23792 8.20914 6.23792 6C6.23792 3.79086 8.02878 2 10.2379 2C12.4471 2 14.2379 3.79086 14.2379 6Z" fill="#333"></path><path d="M16.2355 14.5714C16.2371 14.5477 16.2379 14.5239 16.2379 14.5C16.2379 13.1193 13.5516 12 10.2379 12C6.92421 12 4.23792 13.1193 4.23792 14.5C4.23792 14.5239 4.23872 14.5477 4.24032 14.5714H4.23792V18H16.2379V14.5714H16.2355Z" fill="#333"></path></svg></a>
					</button>
					<button id="nav-x" onClick={toggleNavMenu} type="button">
						<svg id="nav-x-svg" viewBox="0 0 12 12" stroke="black" strokeWidth="1" strokeLinecap="round"><line x1="11" y1="1" x2="1" y2="11"></line><line x1="1" y1="1" x2="11" y2="11"></line></svg>
					</button>
        </Col>
      </Row>

			<div id="nav-menu">
				<Row className="nav-links-row-mini">
					<ul className="nav-links-mini">
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/">Home</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/tech">Tech</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/business">Business</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/sports">Sports</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/arts">Arts</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/books">Books</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/fashion">Fashion</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/food">Food</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/drink">Drink</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/travel">Travel</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/life">Life</a></li>
					</ul>
				</Row>
			</div>

    </Col>
  );
}

export default NavBarMini
