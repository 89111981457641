export const tournamentStarted = () => {
  let now = Date.now();

  // This value is adjustable since teams are hidden until this time.
  //
  // If we want user players to be visible before tournament play actually begins,
  // we set this accordingly
  let tournamentStart = new Date('April 11, 2024 10:00:00')

  return now > tournamentStart;
}
export const tournamentEnded = (mastersData) => {
  if (mastersData.statusRound[3] === 'F' && mastersData.player[0].pos === '1') {
    return true;
  } else if (mastersData.statusRound[4] === 'F') {
    return true;
  }

  return false;
}

export const sortUsersAndPlayers = (users, masters) => {
  const roundNumber = getRoundNumber(masters);
  const players = masters.player;

  for(let index in players){
    players[index] = standardizePlayer(players[index], roundNumber);
  }

  for(let index in users){
    let user = users[index];
    let playerIDs = user['golfers'];
    let userPlayerIDs = [
      playerIDs['wildcard'],
      playerIDs['tier1'],
      playerIDs['tier2'],
      playerIDs['tier3'],
      playerIDs['mid1'],
      playerIDs['mid2'],
      playerIDs['mid3'],
      playerIDs['long']
    ]

    // Get a list of the user's players
    let userPlayers = players.filter((player) => userPlayerIDs.includes(player.id));
    userPlayers.sort((a, b) => a['topar_num'] < b['topar_num'] ? -1 : 1);

    // We will track the gross (unadjusted) and net (with bonuses and multipliers) scores for the user
    let userScoreGross = 0;
    let userScoreNet = 0;

    for(let index in userPlayers) {
      let player = userPlayers[index];
      // We will track the gross (unadjusted) and net (with bonuses and multipliers) scores for the player
      let playerScoreGross = player.topar_num;
      let playerScoreNet = player.topar_num;
      let playerPosition = player.pos;

      if (playerPosition[0] == 'T') {
        playerPosition = playerPosition.slice(1);
      } else if (playerPosition == '') {
        playerPosition = players.length;
      } else {
        playerPosition = Number(playerPosition);
      }
      player.position = playerPosition;

      // Only count aggregate user score for top-5 players
      if (index >= 5) {
        player.scoreGross = playerScoreGross;
        player.scoreNet = playerScoreNet;
        continue;
      }

      // Add .5 multiplier for wildcard under par
      if (playerIDs['wildcard'] === player.id && playerScoreGross < 0) {
        playerScoreNet += playerScoreGross * 0.5;
      }

      // Add first place, top-5, and top-10 bonuses
      if (playerPosition === 0) {
      } else if (playerPosition === 1) {
        playerScoreNet -= 3;
      } else if (playerPosition <= 5) {
        playerScoreNet -= 2;
      } else if (playerPosition <= 10) {
        playerScoreNet -= 1;
      }

      // 2024 Rule
      // If you pick Scottie Scheffler, you get +2 strokes
      if (player.id == '46046') {
        playerScoreNet += 2;
      }

      player.scoreGross = playerScoreGross;
      player.scoreNet = playerScoreNet;

      userScoreGross += playerScoreGross;
      userScoreNet += playerScoreNet;
    }

    user.scoreGross = userScoreGross;
    user.scoreNet = userScoreNet;
    user.playersSorted = JSON.parse(JSON.stringify(userPlayers));
  }

  users.sort((a, b) => (a.scoreNet < b.scoreNet) ? -1 : 1)

  return users;
}

export const sortPlayers = (masters) => {
  const roundNumber = getRoundNumber(masters);
  const players = masters.player;

  for(let index in players){
    players[index] = standardizePlayer(players[index], roundNumber);
  }

  players.sort((a, b) => (a.topar_num < b.topar_num) ? -1 : 1)

  return players;
}

// Standardize the data to better work for sorting players
export const standardizePlayer = (player, roundNumber) => {
  player['topar_num'] = player['topar']

  if(player['topar_num'] == ''){
    // empty at beginning of tournament
    player['topar'] = 'E'
    player['topar_num'] = 0
  } else if(player['topar_num'] == 'E'){
    // when topar is E, set topar_num to 0
    player['topar_num'] = 0
  }

  player['topar_num'] = parseInt(player['topar_num'])

  if(player['thru'] == ''){
    player['thru'] = '-'
  }

  // set today_num to today
  player['today_num'] = player['today']

  // empty today score then set a dash
  if(player['today'] == ''){
    player['today'] = '-'
    player['today_num'] = '-'
  }
  // dash today score, pre-tourney set to 0/E
  if(player['today'] == '-'){
    player['today'] = 'E'
    player['today_num'] = 0
  }
  // when today is E, set today_num to 0
  if(player['today'] == 'E'){
    player['today_num'] = 0
  }

  // withdrawn or missed cut
  if(player['status'] == 'W' || player['status'] == 'C'){
    var roundsPlayed = 0
    if(player['round1']['total'] == null){
      roundsPlayed = 0
    }
    else if(player['round2']['total'] == null){
      roundsPlayed = 1
    }
    else if(player['round3']['total'] == null){
      roundsPlayed = 2
    }
    else if(player['round4']['total'] == null){
      roundsPlayed = 3
    }

    player.topar_num = player.topar_num + (8 * (roundNumber - roundsPlayed))

    if(player.topar_num > 0){
      player.topar = '+' + player.topar_num
    }
    else{
      player.topar = player.topar_num
    }
  }

  return player;
}

export const getRoundNumber = (mastersData) => {
  let round = mastersData.currentRound.indexOf('1');
  if (mastersData.statusRound[round + 1] == 'N' || round == 0) {
    round++;
  }
  return round;

  // Outdated - Round Status below

  // The 5-character status round signifies the following:
  //
  // [N, F, P]  => 1st Round [N]ot Started, [F]inished, [P]laying
  // [N, F, P]  => 2nd Round [N]ot Started, [F]inished, [P]laying
  // [N, F, P]  => 3rd Round [N]ot Started, [F]inished, [P]laying
  // [N, F, P]  => 4th Round [N]ot Started, [F]inished, [P]laying
  // [N, F]     => Tournament [N]ot Finished, [F]inished
  //
  // NNNNN => Tournament Not Started
  // FPNNN => Round 1 Finished, Round 2 In Progress
  // FFNNN => Round 1 + 2 Finished
  // FFFFF => Tournament Finished

  // First check if a round is in progress
  let roundNumber = mastersData.statusRound.split('').findIndex(el => el == 'P')

  // If no round is in progress, find the last finished round
  if(roundNumber == -1){
    roundNumber = 5 - mastersData.statusRound.split('').reverse().findIndex(el => el == 'F')
  }
  return roundNumber + 1;
}

export const playerTierLabel = (user, player) => {
  if(player.id == user.golfers.tier1) {
    return 'T1';
  } else if(player.id == user.golfers.tier2) {
    return 'T2';
  } else if(player.id == user.golfers.tier3) {
    return 'T3';
  } else if(player.id == user.golfers.mid1) {
    return 'M1';
  } else if(player.id == user.golfers.mid2) {
    return 'M2';
  } else if(player.id == user.golfers.mid3) {
    return 'M3';
  } else if(player.id == user.golfers.wildcard) {
    return 'WC';
  } else if(player.id == user.golfers.long) {
    return 'LS';
  }
}

export const showPlayers = (userClassName) => {
  let allUserPlayerRows = document.getElementsByClassName('user-player-row');
  let turnOffLater = []

  Array.from(allUserPlayerRows).forEach((playerRow, i) => {
    if (!playerRow.classList.contains("hidden")) {
      turnOffLater.push(playerRow);
    }
  });

  let userPlayerRows = document.getElementsByClassName(userClassName);

  Array.from(userPlayerRows).forEach((playerRow, i) => {
    if (playerRow.classList.contains("hidden")) {
      playerRow.classList.remove("hidden");
    } else {
      playerRow.classList.add("hidden");
    }
  });

  turnOffLater.forEach((playerRow, i) => {
    playerRow.classList.add("hidden");
  });
}

export const getPlayerHoleScores = (players, roundNumber, pars) => {
  let runningTotals = [];
  let maxThru = 0;
  // Takes into account when players start on back 9 and their thru has an asterisk (*)
  // Eg.
  // thru: 5  => Player is through hole 5
  // thru: 5* => Player is through hole 14
  let adjustedThru;

  players.forEach((player, i) => {
    let thru = player.thru
    if (thru == 'F*') {
      thru = 'F';
    }
    if(thru.slice(-1) == '*'){
      adjustedThru = Number(thru.slice(0, -1));
      maxThru = 18;
    }
    if (thru == 'F') {
      maxThru = 18;
    } else if (thru !== '' && Number(thru) > maxThru) {
      maxThru = Number(thru);
    }
    let roundData = player[`round${roundNumber}`];
    let prior = roundData.prior || 0;
    let roundScores = roundData.scores;
    let roundScoresToPar;
    let runningTotal;

    let score;
    if(player.topar == 'E') {
      score = 0;
    } else {
      score = Number(player.topar);
    }

    if (thru.slice(-1) == '*') {
      adjustedThru = Number(thru.slice(0, -1));

      roundScores = roundScores.slice(9,18).concat(roundScores.slice(0,9));
      roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - pars[hole]));
      runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
      runningTotal = runningTotal.slice(0, adjustedThru)
      runningTotal = runningTotal.concat(Array(18 - adjustedThru).fill(score))
      runningTotal = runningTotal.slice(9,18).concat(runningTotal.slice(0,9));
    } else {
      roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - pars[hole]));
      runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
    }

    if (player.status == 'F') {
      // Do nothing here
    } else if (thru.slice(-1) == '*') {
      let adjustedThru = Number(thru.slice(0, -1));

      if (adjustedThru < 9) {
        runningTotal = Array(9).fill(score).concat(runningTotal.slice(0, adjustedThru)).concat(Array(9 - adjustedThru).fill(score))
      } else if(adjustedThru > 9) {
        runningTotal = runningTotal.slice(0, adjustedThru - 9).concat(Array(18 - adjustedThru).fill(score)).concat(runningTotal.slice(9,18))
      } else {
        runningTotal = Array(9).fill(score).concat(runningTotal.slice(9,18))
      }
    } else if (player.status == 'C') {
      runningTotal = Array(18).fill(8);
      maxThru = 18;
    } else if (thru != '' && thru != '-' && thru != 'F') {
      runningTotal = runningTotal.slice(0, player.thru).concat(Array(18 - player.thru).fill(score));
    } else {
      runningTotal = Array(18).fill(prior);
    }
    runningTotals.push(runningTotal);
  });

  return [runningTotals, maxThru];
}

export const userHoleScore = (score) => {
  if (!Number.isInteger(score) && score == '') {
    return <td className="masters-table-data hole"><span></span></td>
  } else if (score == 0) {
    return <td className="masters-table-data hole not-below-par"><span>0</span></td>
  } else if (score > 0) {
    return <td className="masters-table-data hole not-below-par"><span>{ Math.abs(score) }</span></td>
  } else if (score < 0) {
    return <td className="masters-table-data hole below-par"><span>{ Math.abs(score) }</span></td>
  } else {
    return <td className="masters-table-data hole"><span></span></td>
  }
}
export const userGrossScore = (user) => {
  return leaderboardTotalElement(user.scoreGross);
}
export const userNetScore = (user) => {
  return leaderboardTotalElement(user.scoreNet);
}

export const playerRoundScores = (player, roundNumber, pars) => {
  let playerScore = playerRunningTotal(player, roundNumber, pars)

  let scores = playerScore.map((score) => playerHoleScore(score))
  return scores;
}

const playerHoleScore = (score) => {
  let classList = ['hole']

  if (!Number.isFinite(score)) {
    if (score === 'MC') {
      classList.push('missed-cut')
    } else if (score === 'WD') {
      classList.push('withdrew')
    }
  } else if (score >= 0) {
    classList.push('not-below-par')
  } else if (score < 1) {
    classList.push('below-par')
  }

  return leaderboardElement(score, classList);
}

const playerRunningTotal = (player, roundNumber, pars) => {
  let thru = player.thru;
  let adjustedThru;
  let roundData = player[`round${roundNumber}`];

  if (thru == 'F*') {
    thru = 'F';
  }

  let roundScores = roundData.scores;
  let scores = [];

  if (roundNumber > 2 && player.status == 'C') {
    scores = [...Array(18).fill('MC')];
    return scores;
  } else if (player.status == 'W') {
    scores = [...Array(18).fill('WD')];
    return scores;
  } else if (thru == '-' && player.status != 'C') {
    scores = [...Array(18).fill('')];
    return scores;
  }

  let prior = roundData.prior || 0;

  let roundScoresToPar;
  let runningTotal;
  let inverted_pars = pars.slice(9,18).concat(pars.slice(0,9));

  if (thru.slice(-1) == '*') {
    adjustedThru = Number(thru.slice(0, -1));

    roundScores = roundScores.slice(9,18).concat(roundScores.slice(0,9));
    roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - inverted_pars[hole]));
    runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)

    runningTotal = runningTotal.slice(0, adjustedThru)

    runningTotal = runningTotal.concat(Array(18 - adjustedThru).fill(''))

    runningTotal = runningTotal.slice(9,18).concat(runningTotal.slice(0,9));
  } else {
    roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - pars[hole]));
    runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
  }


  if (thru.slice(-1) == '*') {
    adjustedThru = Number(thru.slice(0, -1));

    if (adjustedThru < 9) {
      runningTotal = Array(9).fill('').concat(runningTotal.slice(0, adjustedThru)).concat(Array(9 - adjustedThru).fill(''))
    } else if(adjustedThru > 9) {
      runningTotal = runningTotal.slice(0, adjustedThru - 9).concat(Array(18 - adjustedThru).fill('')).concat(runningTotal.slice(9,18))
    } else {
      runningTotal = Array(9).fill('').concat(runningTotal.slice(9,18))
    }
  } else if (thru != '' && thru != '-' && thru != 'F') {
    runningTotal = runningTotal.slice(0, thru).concat(Array(18 - thru).fill(''));
  }

  return runningTotal;
}

export const playerGrossScore = (player) => {
  if (player.status === 'C') {
    return leaderboardTotalElement('MC');
  } else if (player.status === 'W') {
    return leaderboardTotalElement('WD');
  } else {
    return leaderboardTotalElement(player.scoreGross || player.topar_num);
  }
}

export const playerNetScore = (player, roundNumber) => {
  if (player.status === 'C') {
    return leaderboardTotalElement('MC');
  } else if (player.status === 'W') {
    return leaderboardTotalElement('WD');
  } else {
    return leaderboardTotalElement(player.scoreNet);
  }
}
export const playerThru = (player) => {
  if (player.status === 'C') {
    return leaderboardThruElement('MC');
  } else if (player.status === 'W') {
    return leaderboardThruElement('WD');
  } else {
    return leaderboardThruElement(player.thru, ['thru']);
  }
}

const leaderboardThruElement = (thru, typeClasses=[]) => {
  let classList = ['masters-table-data', 'total', 'extra-width'].concat(typeClasses)

  const classListMap = {
    'MC': 'missed-cut',
    'WD': 'withdrew'
  }

  if (!Number.isFinite(thru)) {
    classList.push(classListMap[thru] || '');
  }

  return <td className={classList.join(' ')}><span>{ thru }</span></td>
}

const leaderboardTotalElement = (score) => {
  return leaderboardElement(score, ['total', 'extra-width']);
}

const leaderboardHoleElement = (score) => {
  return leaderboardElement(score, ['hole']);
}

const leaderboardElement = (score, typeClasses=[]) => {
  let classList = ['masters-table-data'].concat(typeClasses)

  const classListMap = {
    'MC': 'missed-cut',
    'WD': 'withdrew'
  }

  if (!Number.isFinite(score)) {
    classList.push(classListMap[score] || '');
    // return <td className={classList.join(' ')}><span>{ score || '\u00A0' }</span></td>
  } else if (score >= 0) {
    classList.push('not-below-par');
    score = Math.abs(score)
  } else if (score < 0) {
    classList.push('below-par');
    score = Math.abs(score)
  }

  return <td className={classList.join(' ')}><span>{ score }</span></td>
}
