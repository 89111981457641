import React, { Component, useState, useEffect } from 'react';

import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import Account from './components/Account';
import Login from './components/Login';

import FrontPage from './components/FrontPage.js';
import Tech from './components/Tech.js'
import Business from './components/Business.js'
import Sports from './components/Sports.js'
import Arts from './components/Arts.js'
import Books from './components/Books.js'
import Fashion from './components/Fashion.js'
import Food from './components/Food.js'
import Drink from './components/Drink.js'
import Travel from './components/Travel.js'
import GameOfLife from './components/GameOfLife.js'
import Games from './components/Games.js'
import Tichu from './components/Games/Tichu.js'

import Article from './components/Article.js'
import ArticleNew from './components/ArticleNew.js'
// import Article from './components/Article.js'

import Masters from './components/Sports/Masters.js'
import MastersRefactor from './components/Sports/MastersRefactor.js'
import MastersAugusta from './components/Sports/MastersAugusta.js'
import MastersAugustaRefactor from './components/Sports/MastersAugustaRefactor.js'
import MastersNewUser from './components/Sports/MastersNewUser.js'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';

function App() {

  const [theme, setTheme] = useState({
    colorPrimary: 'black',
    colorPrimaryHover: 'green',
    backgroundColor: 'white',
    divider: '#C7C7C7',
    navDividerTop: 'lightgrey',
    navDividerBottom: 'black',
    gameOfLifeBackground: 'blue',
    gameOfLifePrimary: 'white',
    gameOfLifeHover: 'yellow'
  })

  const {colorPrimary, colorPrimaryHover, backgroundColor, divider, navDividerTop, navDividerBottom} = theme;

  const changeTheme = (event) => {
    const {name, value} = event.target;
    setTheme(prevState => ({...prevState, [name]: value}));
  }


  const [articles, setArticles] = useState([]);
  const articlesCollectionRef = collection(db, 'articles');

  useEffect(() => {
    const getArticles = async () => {
      const data = await getDocs(articlesCollectionRef)
      setArticles(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
      // console.log(articles)
      // const t = Date(articles[0].created.seconds * 1000)
      // console.log(t)
    }

    getArticles()
  }, [])

  return (
    <Router>
      <div className="App colorPrimary colorPrimaryHover backgroundColor divider navDividerTop navDividerBottom">
        <Routes>
          <Route exact path="/" element={<FrontPage/>}/>

          <Route exact path="/account" element={<Account/>}/>
          <Route exact path="/login" element={<Login/>}/>

          <Route exact path="/tech" element={<Tech/>}/>
          <Route exact path="/business" element={<Business/>}/>
          <Route exact path="/sports" element={<Sports/>}/>
          <Route exact path="/arts" element={<Arts/>}/>
          <Route exact path="/books" element={<Books/>}/>
          <Route exact path="/fashion" element={<Fashion/>}/>
          <Route exact path="/food" element={<Food/>}/>
          <Route exact path="/drink" element={<Drink/>}/>
          <Route exact path="/travel" element={<Travel/>}/>
          <Route exact path="/games" element={<Games/>}/>
          <Route exact path="/games/tichu" element={<Tichu/>}/>
          <Route exact path="/life" element={<GameOfLife/>}/>

          <Route exact path="/articles/new" element={<ArticleNew/>}/>
          <Route exact path="/articles/:id" element={<Article/>}/>

          <Route exact path="/masters/old" element={<Masters/>}/>
          <Route exact path="/masters" element={<MastersRefactor/>}/>
          <Route exact path="/masters/new" element={<MastersNewUser/>}/>
          <Route exact path="/masters/augusta" element={<MastersAugustaRefactor/>}/>
          <Route exact path="/masters/augusta/old" element={<MastersAugusta/>}/>

        </Routes>

      </div>
    </Router>
  );
}

// { articles.map((article) => {
//   return (
//     <div key={ article.id + "-div" }>
//       <h1 key={ article.id + "-title"}>Title: { article.title }</h1>
//       <h1 key={ article.id + "-author"}>Author: { article.author }</h1>
//       <h1 key={ article.id + "-subtitle"}>subtitle: { article.subtitle }</h1>
//       <br/>
//     </div>
//   )
// })}

export default App;
