import React, { Component, useRef, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import NavBarMini from '../NavBarMini.js';
import Footer from '../Footer.js';
import LoadingAnimation from '../LoadingAnimation';
import MastersAugusta from './MastersAugusta';

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';

import masters_final_data_2023 from './masters_final_data_2023';

const currentYear = '2024'
const currentYearData = 'data2024'

function updateData() {
  // get the updated data
  fetch(`https://www.masters.com/en_US/scores/feeds/${currentYear}/scores.json`)
    .then((response) => response.json())
    .then((data) => {
      let mastersData = data.data;

      console.log(mastersData)

      // update metadata
      const dataRef = doc(db, "masters", currentYearData);
      updateDoc(dataRef, {
        currentRound: mastersData.currentRound,
        wallClockTime: mastersData.wallClockTime,
        statusRound: mastersData.statusRound,
        yardages: mastersData.yardages,
        pars: mastersData.pars
      })

      let players = mastersData.player;

      // merge player data
      players.forEach((player) => {
        const playerRef = doc(db, "masters", currentYearData, "players", player.id);

        // // set player for the first time before the tournament begins
        // setDoc(playerRef, player);

        // update player during the round
        setDoc(playerRef, {
          group: player.group,
          groupHistory: player.groupHistory,
          holeProgress: player.holeProgress,
          lastHoleWithShot: player.lastHoleWithShot,
          live: player.live,
          movement: player.movement,
          newStatus: player.newStatus,
          pos: player.pos,
          round1: player.round1,
          round2: player.round2,
          round3: player.round3,
          round4: player.round4,
          sort_order: player.sort_order,
          start: player.start,
          status: player.status,
          tee_order: player.tee_order,
          teetime: player.teetime,
          thru: player.thru,
          thruHistory: player.thruHistory,
          today: player.today,
          topar: player.topar,
          total: player.total,
          totalUnderPar: player.totalUnderPar
        }, { merge: true });
      })
    })
}

let defaultData = {
  currentRound: '1000',
  statusRound: 'NNNNN',
  wallClockTime: '0',
  player: [],
  yardages: [],
  pars: []
}

const Masters = () => {
  // call updateData() at some interval of time
  // updateData()
  const [mastersData, setMastersData] = useState(defaultData);
  const [users, setUsers] = useState([]);
  const [userPlayers, setUserPlayers] = useState([]);
  // const [players, setPlayers] = useState([]);
  // const [tournamentData, setTournamentData] = useState([]);
  const [leaderboardReady, setLeaderboardReady] = useState(false);
  const [datePublished, setDatePublished] = useState("");

  useEffect(() => {
    getMastersData();
    // getTournamentData();
    // getPlayers();
    getUsers();
    setLeaderboardReady(true);
  }, [])

  const getMastersData = async () => {
    // TESTING
    // setMastersData(masters_final_data_2023.data)

    // PRODUCTION
    fetch(`https://www.masters.com/en_US/scores/feeds/${currentYear}/scores.json`)
      .then((response) => response.json())
      .then((data) => {
        setMastersData(data.data);
      })
  }

  const getUsers = async () => {
    const docs = await getDocs(collection(db, "masters", currentYearData, "users"));
    let users = [];
    let userIDs = [];

    docs.forEach((doc) => {
      userIDs.push(doc.id);
      users.push(doc.data());
    });

    // // Use this for transferring data to a new document name
    // for(let i=0; i< users.length; i++) {
    //   await setDoc(doc(db, 'masters', 'data2023', 'users', userIDs[i]), users[i])
    // }

    setUsers(users);
  }

  const getPlayers = async () => {
    // // TESTING
    // const docsTest = await getDocs(collection(db, "masters", currentYearData, "players_test"));
    // let playersTest = [];
    // let playersIDsTest = [];

    // PRODUCTION
    const docsProd = await getDocs(collection(db, "masters", currentYearData, "players"));
    let playersProd = [];
    let playersIDsProd = [];

    // // Use this for transferring data to a new document name
    // docsTest.forEach((doc) => {
    //   playersIDsTest.push(doc.id)
    //   playersTest.push(doc.data());
    // });
    // docsProd.forEach((doc) => {
    //   playersIDsProd.push(doc.id)
    //   playersProd.push(doc.data());
    // });
    // for(let i=0; i< playersTest.length; i++) {
    //   await setDoc(doc(db, 'masters', 'data2023', 'players_test', playersIDsTest[i]), playersTest[i])
    //   await setDoc(doc(db, 'masters', 'data2023', 'players', playersIDsProd[i]), playersProd[i])
    // }

    // playersProd.sort((a,b) => a.last_name > b.last_name ? 1:-1);
    // setPlayers(playersProd);
  }

  // const getTournamentData = async () => {
  //   // TESTING
  //
  //   // PRODUCTION
  //   const dataRef = doc(db, "masters", "data");
  //   const dataDoc = await getDoc(dataRef);
  //   setTournamentData(dataDoc.data());
  // }

  function updatedPlayer(p){
    p['topar_num'] = p['topar']

    // empty topar at beginning of tournament
    if(p['topar_num'] == ''){
      p['topar'] = 'E'
      p['topar_num'] = 0
    }

    // when topar is E, set topar_num to 0
    if(p['topar_num'] == 'E'){
      p['topar_num'] = 0
    }

    p['topar_num'] = parseInt(p['topar_num'])

    if(p['thru'] == ''){
      p['thru'] = '-'
    }

    // set today_num to today
    p['today_num'] = p['today']

    // empty today score then set a dash
    if(p['today'] == ''){
      p['today'] = '-'
      p['today_num'] = '-'
    }

    // dash today score, pre-tourney set to 0/E
    if(p['today'] == '-'){
      p['today'] = 'E'
      p['today_num'] = 0
    }

    // when today is E, set today_num to 0
    if(p['today'] == 'E'){
      p['today_num'] = 0
    }

    return p
  }

  function golferLookup(players){
    var golferMap = {}
    for(var index in players){
      let player = players[index]
      golferMap[player['id']] = player
    }
    return golferMap
  }

  function rankUsers(users){
    var currentRound = mastersData.currentRound
    var wallClockTime = mastersData.wallClockTime
    var statusRound = mastersData.statusRound
    var yardages = mastersData.yardages
    var pars = mastersData.pars
    var cutline = mastersData.cutline

    var roundNum = statusRound.split('').findIndex(el => el == 'P')

    if(roundNum == -1){
      roundNum = 5 - statusRound.split('').reverse().findIndex(el => el == 'F')
    }
    roundNum++

    let golferMap = golferLookup(mastersData.player)

    let updatedPlayers = [];

    for(var i in mastersData.player){
      let p = updatedPlayer(mastersData.player[i]);
      if (p.already_updated == true) {
        continue;
      }

      // withdrawn or missed cut
      if(p['status'] == 'W' || p['status'] == 'C'){
        var roundsPlayed = 0
        if(p['round1']['total'] == null){
          roundsPlayed = 0
        }
        else if(p['round2']['total'] == null){
          roundsPlayed = 1
        }
        else if(p['round3']['total'] == null){
          roundsPlayed = 2
        }
        else if(p['round4']['total'] == null){
          roundsPlayed = 3
        }

        p.topar_num = p.topar_num + (8 * (roundNum - roundsPlayed))

        if(p.topar_num > 0){
          p.topar = '+' + p.topar_num
        }
        else{
          p.topar = p.topar_num
        }

      }
      p.already_updated = true;
      updatedPlayers.push(p);
    }

    let players = updatedPlayers;

    for(var index in users){
      var user = users[index]
      var g = user['golfers']
      var wc = golferMap[g['wildcard']]
      var t1 = golferMap[g['tier1']]
      var t2 = golferMap[g['tier2']]
      var t3 = golferMap[g['tier3']]
      var m1 = golferMap[g['mid1']]
      var m2 = golferMap[g['mid2']]
      var m3 = golferMap[g['mid3']]
      var ls = golferMap[g['long']]
      var user_players = [wc, t1, t2, t3, m1, m2, m3, ls]

      // sort players for a given user
      user_players.sort((a, b) => a['topar_num'] < b['topar_num'] ? -1 : 1)

      var score = 0
      var raw_score = 0
      for(var i in user_players) {

        var player = user_players[i]
        var player_topar_num = player['topar_num']
        var position = player['pos']
        if(position[0] == 'T'){
          position = position.slice(1)
        }
        else if(position == ""){
          position = players.length
        }
        player.position = position;

        if(user['golfers']['wildcard'] == player['id'] && i < 5){
          if(player_topar_num < 0){
            score += player_topar_num * 1.5
            raw_score += player_topar_num
          }
          else{
            score += player_topar_num
            raw_score += player_topar_num
          }
        }
        else if(i < 5){
          score += player_topar_num
          raw_score += player_topar_num
        }

        if(position == 0){
          // skip these players
        } else if(position == 1){
          score -= 3
        }
        else if(position <= 5){
          score -= 2
        }
        else if(position <= 10){
          score -= 1
        }

        // 2024 Rule
        // If you pick Scottie Scheffler, you get a 2-stroke handicap
        if (!tournamentNotStartedYet()) {
          if (player.id == '46046') {
            score += 2;
          }
        }
      }

      user['score'] = score
      user['raw_score'] = raw_score

      // re-sort based on withdrawn status
      // user_players.sort((a, b) => sortPlayerStatus(a, b) ? -1 : 1)

      user['golfers_sorted'] = user_players.slice()
    }
    users.sort((a, b) => (a['score'] < b['score']) ? -1 : 1)

    return users;
  }

  // returns the correct round
  function currentScores (player) {
    let round = currentRoundToShow ();
    if (round == 1){
      return player.round1
    }
    else if (round == 2){
      return player.round2
    }
    else if (round == 3){
      return player.round3
    }
    else if (round == 4){
      return player.round4
    }
  }

  function currentRoundToShow () {
    let round = mastersData.currentRound.indexOf('1');
    if (mastersData.statusRound[round + 1] == 'N' || round == 0) {
      round++;
    }
    return round;
  }

  function playerTotalLeaderboardGross (player) {
    let topar = player.topar;
    let status = player.status;
    let round = currentRoundToShow();
    if (topar == null || topar == '') {
      topar = 0;
    }

    if (round > 2 && status == 'C') {
      return <td className="masters-table-data total missed-cut extra-width"><span>MC</span></td>
    } else if (status == 'W') {
      return <td className="masters-table-data total withdrew extra-width"><span>WD</span></td>
    }

    if (topar == 0 || topar == "E" ) {
      return <td className="masters-table-data total not-below-par extra-width"><span>0</span></td>
    } else if (Number(topar) > 0) {
      let score = Number(topar)
      return <td className="masters-table-data total not-below-par extra-width"><span>{ Math.abs(score) }</span></td>
    } else {
      let score = Number(topar)
      return <td className="masters-table-data total below-par extra-width"><span>{ Math.abs(score) }</span></td>
    }
  }

  function playerTotalLeaderboardNet (player, user) {
    let topar = player.topar;
    let status = player.status;
    let round = currentRoundToShow();
    if (topar == null || topar == '') {
      topar = 0;
    }

    if (round > 2 && status == 'C') {
      return <td className="masters-table-data total missed-cut extra-width"><span>MC</span></td>
    } else if (status == 'W') {
      return <td className="masters-table-data total withdrew extra-width"><span>WD</span></td>
    }

    let net_score;

    if (topar == 'E') {
      net_score = 0;
    } else {
      net_score = Number(topar);
    }


    let position = player.position
    if(position[0] == 'T'){
      position = position.slice(1)
    }

    if (user.golfers.wildcard == player.id && player.net_score < 0) {
      net_score *= 1.5;
    }

    if (!tournamentNotStartedYet()) {
      if(position == 0){
        // skip these players
      } else if(position == 1){
        net_score -= 3
      } else if (position <= 5) {
        net_score -= 2;
      } else if (position <= 10) {
        net_score -= 1;
      }
    }

    // 2024 Rule
    // If you pick Scottie Scheffler, you get a 2-stroke handicap
    if (!tournamentNotStartedYet()) {
      if (player.id == '46046') {
        net_score += 2;
      }
    }

    if (net_score == 0) {
      return <td className="masters-table-data total not-below-par extra-width"><span>0</span></td>
    } else if (net_score > 0) {
      return <td className="masters-table-data total not-below-par extra-width"><span>{ Math.abs(net_score) }</span></td>
    } else {
      return <td className="masters-table-data total below-par extra-width"><span>{ Math.abs(net_score) }</span></td>
    }
  }

  function playerScoreLeaderboard (score) {
    if (!Number.isInteger(score) && score == ''){
      return <td className="masters-table-data hole"><span></span></td>
    } else if (score == 0) {
      return <td className="masters-table-data hole not-below-par"><span>0</span></td>
    } else if (score > 0) {
      return <td className="masters-table-data hole not-below-par"><span>{ Math.abs(score) }</span></td>
    } else if (score < 0) {
      return <td className="masters-table-data hole below-par"><span>{ Math.abs(score) }</span></td>
    } else {
      return <td className="masters-table-data hole"><span></span></td>
    }
  }

  function playerScoreRowLeaderboard (player) {
    let status = player.status;
    let thru = player.thru;
    let round = currentRoundToShow();
    let roundData = currentScores(player);

    if (thru == 'F*') {
      thru = 'F';
    }

    let roundScores = roundData.scores;
    let scores = [];

    if (round > 2 && status == 'C') {
      scores = [...Array(18)].map((e, hole) => <td className="masters-table-data hole missed-cut" key={player.id + "-" + (hole + 1)}><span>MC</span></td>);
      return scores;
    } else if (status == 'W') {
      scores = [...Array(18)].map((e, hole) => <td className="masters-table-data hole withdrew" key={player.id + "-" + (hole + 1)}><span>WD</span></td>);
      return scores;
    } else if (thru == '-' && status != 'C') {
      scores = [...Array(18)].map((e, hole) => <td className="masters-table-data hole" key={player.id + "-" + (hole + 1)}><span></span></td>);
      return scores;
    }

    let prior = roundData.prior || 0;

    let roundScoresToPar;
    let runningTotal;
    // console.log(thru)
    // console.log(player)
    let pars = mastersData.pars.round1
    let inverted_pars = pars.slice(9,18).concat(pars.slice(0,9));

    if (thru.slice(-1) == '*') {
      let adjustedThru = Number(thru.slice(0, -1));

      roundScores = roundScores.slice(9,18).concat(roundScores.slice(0,9));
      roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - inverted_pars[hole]));
      runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)

      runningTotal = runningTotal.slice(0, adjustedThru)

      runningTotal = runningTotal.concat(Array(18 - adjustedThru).fill(''))

      runningTotal = runningTotal.slice(9,18).concat(runningTotal.slice(0,9));
      // console.log('runningTotal:', runningTotal)
    } else {
      roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - pars[hole]));
      runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
    }


    if (thru.slice(-1) == '*') {
      let adjustedThru = Number(thru.slice(0, -1));

      if (adjustedThru < 9) {
        runningTotal = Array(9).fill('').concat(runningTotal.slice(0, adjustedThru)).concat(Array(9 - adjustedThru).fill(''))
      } else if(adjustedThru > 9) {
        runningTotal = runningTotal.slice(0, adjustedThru - 9).concat(Array(18 - adjustedThru).fill('')).concat(runningTotal.slice(9,18))
      } else {
        runningTotal = Array(9).fill('').concat(runningTotal.slice(9,18))
      }
    } else if (thru != '' && thru != '-' && thru != 'F') {
      runningTotal = runningTotal.slice(0, thru).concat(Array(18 - thru).fill(''));
    } else {
      runningTotal = Array(18).fill(prior);
    }

    scores = runningTotal.map((score, hole) => playerScoreLeaderboard(score))
    return scores;
  }

  function getPlayerHoleScores(players) {
    let runningTotals = [];
    let maxThru = 0;
    let adjustedThru;

    players.forEach((player, i) => {
      let thru = player.thru
      if (thru == 'F*') {
        thru = 'F';
      }
      if(thru.slice(-1) == '*'){
        let adjustedThru = Number(thru.slice(0, -1));
        // if (adjustedThru > 9) {
        //   thru = 18;
        // } else {
        //   thru = 9 + adjustedThru;
        // }
        maxThru = 18;
      }
      if (thru == 'F') {
        maxThru = 18;
      } else if (thru != '' && thru != '-' && Number(thru) > maxThru) {
        maxThru = Number(thru);
      }
      let roundData = currentScores(player);
      let prior = roundData.prior || 0;
      let roundScores = roundData.scores;
      let roundScoresToPar;
      let runningTotal;

      // console.log('player:', player)
      // console.log('thru:', thru)
      let score;
      if(player.topar == 'E') {
        score = 0;
      } else {
        score = Number(player.topar);
      }

      if (thru.slice(-1) == '*') {
        let adjustedThru = Number(thru.slice(0, -1));

        roundScores = roundScores.slice(9,18).concat(roundScores.slice(0,9));
        roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - mastersData.pars.round1[hole]));
        runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
        // console.log('runningTotal:', runningTotal)
        runningTotal = runningTotal.slice(0, adjustedThru)
        runningTotal = runningTotal.concat(Array(18 - adjustedThru).fill(score))
        runningTotal = runningTotal.slice(9,18).concat(runningTotal.slice(0,9));
        // runningTotal = runningTotal.slice(9,18).concat(runningTotal.slice(0,9));
        // console.log('runningTotal:', runningTotal)
      } else {
        roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - mastersData.pars.round1[hole]));
        runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
      }

      if (player.status == 'F') {
        // Do nothing here
      } else if (thru.slice(-1) == '*') {
        let adjustedThru = Number(thru.slice(0, -1));

        if (adjustedThru < 9) {
          runningTotal = Array(9).fill(score).concat(runningTotal.slice(0, adjustedThru)).concat(Array(9 - adjustedThru).fill(score))
        } else if(adjustedThru > 9) {
          runningTotal = runningTotal.slice(0, adjustedThru - 9).concat(Array(18 - adjustedThru).fill(score)).concat(runningTotal.slice(9,18))
        } else {
          runningTotal = Array(9).fill(score).concat(runningTotal.slice(9,18))
        }
      } else if (player.status == 'C') {
        runningTotal = Array(18).fill(8);
        maxThru = 18;
      } else if (thru != '' && thru != '-' && thru != 'F') {
        // console.log('normal score:', runningTotal)

        runningTotal = runningTotal.slice(0, player.thru).concat(Array(18 - player.thru).fill(score));
      } else {
        runningTotal = Array(18).fill(prior);
      }


      // if (thru == '-') {
      //   let runningTotal = Array(18).fill(prior);
      //   runningTotals.push(runningTotal);
      // } else {
      //   let roundScores = roundData.scores;
      //
      //   let roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - mastersData.pars.round1[hole]));
      //   let runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
      //   if (thru != '' && thru != 'F' && thru != '-') {
      //     runningTotal = runningTotal.slice(0, thru).concat(Array(18 - thru).fill(runningTotal[thru - 1]));
      //   }
      //   runningTotals.push(runningTotal);
      // }
      runningTotals.push(runningTotal);

    });

    // console.log(maxThru)
    // runningTotals.map((runningTotal) => {
    //   if (maxThru < 18) {
    //     console.log(runningTotal.slice(0, maxThru).concat(Array(18 - maxThru).fill('')))
    //     runningTotal.slice(0, maxThru).concat(Array(18 - maxThru).fill(''))
    //   }
    // })

    return [runningTotals, maxThru];
  }

  function userScoreLeaderboard (score) {
    if (!Number.isInteger(score) && score == '') {
      return <td className="masters-table-data hole"><span></span></td>
    } else if (score == 0) {
      return <td className="masters-table-data hole not-below-par"><span>0</span></td>
    } else if (score > 0) {
      return <td className="masters-table-data hole not-below-par"><span>{ Math.abs(score) }</span></td>
    } else if (score < 0) {
      return <td className="masters-table-data hole below-par"><span>{ Math.abs(score) }</span></td>
    } else {
      return <td className="masters-table-data hole"><span></span></td>
    }
  }

  function userScoreRowLeaderboard (user) {
    // console.log(user)
    let [playerScores, maxThru] = getPlayerHoleScores(user.golfers_sorted.slice(0,5))
    // console.log(maxThru)
    // console.log(playerScores)
    let userScore = playerScores.reduce((a, b) => a.map((v, i) => v + b[i]));
    // console.log(userScore)

    userScore = userScore.slice(0, maxThru).concat(Array(18 - maxThru).fill(''))
    // console.log(userScore)

    let scores = userScore.map((score, hole) => userScoreLeaderboard(score))
    return scores;
  }

  function userScoreRowLeaderboardWithoutPlayers (user) {
    let userScore = new Array(18).fill(0);
    let scores = userScore.map((score, hole) => userScoreLeaderboard(''))

    return scores;
  }

  function userTotalLeaderboard (user) {
    if (user.raw_score == 0) {
      return <td className="masters-table-data total not-below-par extra-width"><span>{user.raw_score}</span></td>
    } else if (user.raw_score > 0) {
      return <td className="masters-table-data total not-below-par extra-width"><span>{ Math.abs(user.raw_score) }</span></td>
    } else {
      return <td className="masters-table-data total below-par extra-width"><span>{ Math.abs(user.raw_score) }</span></td>
    }
  }

  function userTotalLeaderboardWithoutPlayers (user) {
    return <td className="masters-table-data total not-below-par extra-width"><span>0</span></td>
  }

  function userTotalWithWildCardLeaderboard (user) {
    if (user.score == 0) {
      return <td className="masters-table-data total not-below-par extra-width"><span>{user.score}</span></td>
    } else if (user.score > 0) {
      return <td className="masters-table-data total not-below-par extra-width"><span>{ Math.abs(user.score) }</span></td>
    } else {
      return <td className="masters-table-data total below-par extra-width"><span>{ Math.abs(user.score) }</span></td>
    }
  }

  function userTotalWithWildCardLeaderboardWithoutPlayers () {
      return <td className="masters-table-data total not-below-par extra-width"><span>0</span></td>
  }

  function displayUserPlayers (user) {
    let userPlayers = getPlayersByUser(user);
    let playerElements = userPlayers.map((player) => {
      return (
        <tr id={user.lastName + "-" + player.id + "-row"} className={user.lastName + "-user-row user-player-row hidden"}>
          <td className={`masters-table-data-name player-name ${player.id == user.golfers.wildcard ? "wildcard" : ""}` }>{ player.pos } { player.display_name.toUpperCase() } <div className="player-tier-label">{ playerTierLabel(user, player) }</div></td>
          { playerScoreRowLeaderboard(player) }
          { playerTotalLeaderboardGross(player) }
          { playerTotalLeaderboardNet(player, user) }
        </tr>
      )
    });
    return playerElements;
  }

  function playerTierLabel(user, player) {
    if(player.id == user.golfers.tier1) {
      return 'T1';
    } else if(player.id == user.golfers.tier2) {
      return 'T2';
    } else if(player.id == user.golfers.tier3) {
      return 'T3';
    } else if(player.id == user.golfers.mid1) {
      return 'M1';
    } else if(player.id == user.golfers.mid2) {
      return 'M2';
    } else if(player.id == user.golfers.mid3) {
      return 'M3';
    } else if(player.id == user.golfers.wildcard) {
      return 'WC';
    } else if(player.id == user.golfers.long) {
      return 'LS';
    }
  }

  function getPlayersByUser (user) {

    let userPlayerIds = [
      user.golfers.tier1,
      user.golfers.tier2,
      user.golfers.tier3,
      user.golfers.mid1,
      user.golfers.mid2,
      user.golfers.mid3,
      user.golfers.long,
      user.golfers.wildcard
    ]

    return mastersData.player.filter((player) => userPlayerIds.includes(player.id))
  }

  function showUserPlayers (className) {
    let allUserPlayerRows = document.getElementsByClassName('user-player-row');
    let turnOffLater = []

    Array.from(allUserPlayerRows).forEach((playerRow, i) => {
      if (!playerRow.classList.contains("hidden")) {
        turnOffLater.push(playerRow);
      }
    });

    let userPlayerRows = document.getElementsByClassName(className);

    Array.from(userPlayerRows).forEach((playerRow, i) => {
      if (playerRow.classList.contains("hidden")) {
        playerRow.classList.remove("hidden");
      } else {
        playerRow.classList.add("hidden");
      }
    });

    turnOffLater.forEach((playerRow, i) => {
      playerRow.classList.add("hidden");
    });
  }

  function displayUser(user, place) {
    return (
        <tr id={user.lastName + "-user-row"} className="clickable" onClick={() => showUserPlayers(user.lastName + "-user-row")}>
          <td className="masters-table-data-name user-name">{user.lastName.toUpperCase() }</td>
          { userScoreRowLeaderboard(user) }
          { userTotalLeaderboard(user) }
          { userTotalWithWildCardLeaderboard(user) }
        </tr>
    );
  }

  function displayUserWithoutPlayers (user, place) {
    return (
        <tr id={user.lastName + "-user-row"}>
          <td className="masters-table-data-name user-name">{user.lastName.toUpperCase() }</td>
          { userScoreRowLeaderboardWithoutPlayers(user) }
          { userTotalLeaderboardWithoutPlayers(user) }
          { userTotalWithWildCardLeaderboardWithoutPlayers(user) }
        </tr>
    );
  }

    function displayUsers (users) {
    let user_elements = users.map((user, place) => displayUserWithoutPlayers(user, place+1) )

    return user_elements;
  }

  function displayUserAndPlayer (user, place) {
    return (
      <>
        { displayUser(user, place) }
        { displayUserPlayers(user) }
      </>
    )
  }

  function displayUsersAndPlayers (users) {
    let sorted_users = rankUsers(users);

    let user_elements = sorted_users.map((user, place) => displayUserAndPlayer(user, place+1) )

    return user_elements;
  }

  function tournamentNotStartedYet () {
    return false;
    return mastersData.statusRound === 'NNNNN';
  }

  if (leaderboardReady == false) {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <div className="masters-content">
          <div className="masters-header">
            {/* { updateData() } */}
            <div className="masters-header-top">
              <a className="masters-type" href="https://www.masters.com/">The Masters</a>
              <p className={ "masters-title-standard" }>{currentYear} 1A Masters Pool</p>
              <a className="masters-type" href="/masters/augusta">Leaders At Augusta</a>
              {
                tournamentNotStartedYet() ?
                <p className="masters-type-paragraph">Leaderboard will update once the tournament has begun.</p> :
                <div>
                  <p className="masters-type-paragraph large-screen-only">Leaderboard extends through the hole of your furthest along top-5 player and shows your gross score (though bonuses are not shown, users are ranked by net score with bonuses).</p>
                  <p className="masters-type-paragraph small-screen-only">Gross score shows sum of your top 5 players.</p>
                  <p className="masters-type-paragraph-small small-screen-only">Net score factors in bonus points.</p>
                  <p className="masters-type-paragraph-small small-screen-only">1st Place: -3</p>
                  <p className="masters-type-paragraph-small small-screen-only">Top-5: -2</p>
                  <p className="masters-type-paragraph-small small-screen-only">Top-10: -1</p>
                  <p className="masters-type-paragraph-small small-screen-only">WC: x1.5 (under par only)</p>
                  <p className="masters-type-paragraph-small small-screen-only">Scottie Scheffler: +2</p>
                </div>
              }
            </div>
          </div>


          <div className="masters-body">
            <div className="masters-table-container">
              <div className="cut-off-half"><div className="masters-table-heading-title"><span>LEADERS</span></div></div>

              <table className="table masters-table">
                <thead className="masters-table-heading-hole">
                  <tr>
                    <th className="masters-table-heading-name"><span>HOLE</span></th>
                    <th className="masters-table-heading hole"><span>1</span></th>
                    <th className="masters-table-heading hole"><span>2</span></th>
                    <th className="masters-table-heading hole"><span>3</span></th>
                    <th className="masters-table-heading hole"><span>4</span></th>
                    <th className="masters-table-heading hole"><span>5</span></th>
                    <th className="masters-table-heading hole"><span>6</span></th>
                    <th className="masters-table-heading hole"><span>7</span></th>
                    <th className="masters-table-heading hole"><span>8</span></th>
                    <th className="masters-table-heading hole"><span>9</span></th>
                    <th className="masters-table-heading hole"><span>10</span></th>
                    <th className="masters-table-heading hole"><span>11</span></th>
                    <th className="masters-table-heading hole"><span>12</span></th>
                    <th className="masters-table-heading hole"><span>13</span></th>
                    <th className="masters-table-heading hole"><span>14</span></th>
                    <th className="masters-table-heading hole"><span>15</span></th>
                    <th className="masters-table-heading hole"><span>16</span></th>
                    <th className="masters-table-heading hole"><span>17</span></th>
                    <th className="masters-table-heading hole"><span>18</span></th>
                  </tr>
                </thead>
                <thead className="masters-table-heading-par">
                <tr>
                  <th className="masters-table-heading-name"><span>PAR</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                </tr>
                </thead>
                <thead className="masters-table-heading-total">
                  <tr>
                    <th className="masters-table-heading-name"><span>NAME</span></th>
                    <th className="masters-table-heading-small"><span>GROSS</span></th>
                    <th className="masters-table-heading-small"><span>NET</span></th>
                  </tr>
                </thead>
                <tbody>
                  { tournamentNotStartedYet() ? displayUsers(users) : displayUsersAndPlayers(users) }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Masters;
