import React, { Component, useState, useEffect } from 'react';
import NavBar from './NavBar.js';
import NavBarMini from './NavBarMini.js';

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

class Books extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render(){
    return (
      <div className="Books">
        <NavBar />
        <NavBarMini />

        <div className="page-content">
          <div className="page-header">
            <h4 className="page-header-title">Books</h4>
          </div>
          <div className="page-body">

          </div>
        </div>

      </div>
    );
  }
}

export default Books;
