import React, { Component, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const auth = getAuth();

function togglePasswordVisibility () {
  const passwordInput = document.getElementById('passwordInput');
  const togglePassword = document.getElementById('togglePassword');

  if (passwordInput.type == 'password') {
    passwordInput.type = 'text';
    togglePassword.innerText = 'Hide';
  } else {
    passwordInput.type = 'password';
    togglePassword.innerText = 'Show';
  }
}

// function verifyEmail () {
//   const emailAddressInput = document.getElementById('emailAddressInput');
//   const email = emailAddressInput.value;
//   const loginHeaderTitle = document.getElementById('loginHeaderTitle');
//   const loginButton = document.getElementById('loginButton');

//   auth.getUserByEmail(email)
//     .then(() => {
//       loginHeaderTitle.innerText = 'Log in to your account';
//       loginButton.style.display = 'block';
//     })
//     .catch((error) => {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       console.log('error', error)
//       console.log('error code', error.code)
//       console.log('error message', error.message)
//       // auth/invalid-email
//       // auth/configuration-not-found
//       // auth/wrong-password
//       // auth/internal-error

//       if (error.code == 'auth/user-not-found') {
//         loginHeaderTitle.innerText = 'Create your account';
//         loginButton.innerText = 'Create Account';
//         loginButton.style.display = 'block';
//       } else {

//       }
//     });
// }

// function continueLogin () {
//   const emailInputGroup = document.getElementById('emailInputGroup');
//   const emailInputButton = document.getElementById('emailInputButton');
//   const emailAddressInput = document.getElementById('emailAddressInput');
//   const passwordInput = document.getElementById('passwordInput');
//   const forgotPasswordGroup = document.getElementById('forgotPasswordGroup');
//   const passwordField = document.getElementById('passwordField');
//   const continueButton = document.getElementById('continueButton');

//   emailInputButton.style.display = 'block'
//   forgotPasswordGroup.style.display = 'block';
//   passwordField.style.display = 'block';
//   continueButton.style.display = 'none';

//   emailInputGroup.classList.add('faded');
//   emailAddressInput.style.setProperty('width', 'calc(100% - 56px)');
//   passwordInput.style.setProperty('width', 'calc(100% - 56px)');
//   emailAddressInput.disabled = true;
// }

// function revertLogin () {
//   const emailInputGroup = document.getElementById('emailInputGroup');
//   const emailInputButton = document.getElementById('emailInputButton');
//   const emailAddressInput = document.getElementById('emailAddressInput');
//   emailInputGroup.classList.remove('faded');
//   emailInputButton.style.display = 'none'
//   emailAddressInput.style.width = '100%';
//   emailAddressInput.disabled = false;

//   const forgotPasswordGroup = document.getElementById('forgotPasswordGroup');
//   const passwordField = document.getElementById('passwordField');
//   const continueButton = document.getElementById('continueButton');
//   const loginButton = document.getElementById('loginButton');
//   const loginHeaderTitle = document.getElementById('loginHeaderTitle');
//   forgotPasswordGroup.style.display = 'none';
//   passwordField.style.display = 'none';
//   continueButton.style.display = 'block';
//   loginButton.style.display = 'none';
//   loginHeaderTitle.innerText = 'Log in or create an account';
// }

function createAccount () {
  console.log('create account')
  const emailAddressInput = document.getElementById('emailAddressInput');
  const passwordInput = document.getElementById('passwordInput');

  const email = emailAddressInput.value;
  const password = passwordInput.value;

  console.log('signing in with')
  console.log('email:', email)
  console.log('password:', password)

  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // User created and signed in
    const user = userCredential.user;
    console.log('user created!');
    console.log(user);
    window.location.href = "/";
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    const emailInputGroup = document.getElementById('emailInputGroup');
    const passwordInputGroup = document.getElementById('passwordInputGroup');

    const emailInputFieldError = document.getElementById('emailInputFieldError');
    const passwordInputFieldError = document.getElementById('passwordInputFieldError');

    console.log('error')
    console.log(errorCode)
    console.log(errorMessage)

    if (errorCode == 'auth/weak-password') {
      passwordInputGroup.classList.add('input-group-error');
      passwordInputFieldError.style.display = 'block';
      passwordInputFieldError.innerText = 'Password should be at least 6 characters';
    } else {
      // email already in use: auth/email-already-in-use
      emailInputGroup.classList.add('input-group-error');
      emailInputFieldError.style.display = 'block';
      emailInputFieldError.innerText = 'Account creation failed';
    }
  });
}

function login () {
  console.log('login')
  const emailAddressInput = document.getElementById('emailAddressInput');
  const passwordInput = document.getElementById('passwordInput');

  const email = emailAddressInput.value;
  const password = passwordInput.value;

  console.log('signing in with')
  console.log('email:', email)
  console.log('password:', password)

  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log('signed in!', user)
      window.location.href = "/";
      })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('error code', error.code)
      // auth/invalid-email
      // auth/configuration-not-found
      // auth/wrong-password
      console.log('error message', error.message)
    });

  const loginButton = document.getElementById('loginButton');
  if (loginButton.innerText == 'Create Account') {

  } else if (loginButton.innerText == 'Login') {

  }
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return (
      <div className="Login">
        <NavBarMini alwaysShown={true} />

        <div className="login-content">

          <div className="login-form">
            <div className="login-header">
              <h2 id="loginHeaderTitle">Log in or create an account</h2>
            </div>
            <div className="login-body">
              <div id="emailField" className="login-field">
                <label>Email Address</label>
                <div id="emailInputGroup" className="login-input-group">
                  <input type="email" id="emailAddressInput"></input>
                </div>
                <label id="emailInputFieldError"></label>
              </div>
              <div id="passwordField" className="login-field">
                <label>Password</label>
                <div id="passwordInputGroup" className="login-input-group">
                  <input type="password" id="passwordInput"></input>
                  <button id="togglePassword" className="input-btn" type="button" onClick={togglePasswordVisibility}>Show</button>
                </div>
                <label id="passwordInputFieldError"></label>
              </div>
              <div id="forgotPasswordGroup" className="login-forgot-password">
                <p className="login-forgot-password-text">Forgot your password?</p>
              </div>
              <button id="loginButton" className="login-btn" type="submit" onClick={login}>Log In</button>
              <button id="createButton" className="login-btn" type="submit" onClick={createAccount}>Create Account</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
