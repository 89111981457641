import React, { Component } from 'react';

function getTime() {
  const today = new Date();
  var hour = String(today.getHours()).padStart(2, '0');
  var minutes = String(today.getMinutes()).padStart(2, '0');
  var seconds = String(today.getSeconds()).padStart(2, '0');
  var label;
  if(hour > 12) {
    hour -= 12
    label = 'P.M.'
  } else {
    label = 'A.M.'
  }

  // With Seconds
  return hour + ':' + minutes + ':' + seconds + ' ' + label;
  // Without Seconds
  // return hour + ':' + minutes + ' ' + label;
}

class Clock extends Component {
  constructor(props){
    super(props);
    this.state = {
      time: getTime()
    };
  }

  render(){
    return(
      // <div className="line-height-sm">
      //   <span className="nav-text equal-width-numbers"><b>{this.state.time}</b></span>
      // </div>
      <div className="clock-container">
        <div className="clock">
          <div className="arrows">
            <div className="clock-hour"></div>
            <div className="clock-minute"></div>
            <div className="clock-second"></div>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.interval = setInterval(() => this.setState({time: getTime()}), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
}

export default Clock;
