import React, { Component, useRef, useState, useEffect } from 'react';
import NavBarMini from '../NavBarMini.js';
import Footer from '../Footer.js';
import LoadingAnimation from '../LoadingAnimation';

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';

const currentYear = '2024'
const currentYearData = 'data2024'

const MastersNewUser = () => {
  const [users, setUsers] = useState([]);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    getUsers();
    getPlayers();
  }, [])

  const getUsers = async () => {
    const docs = await getDocs(collection(db, "masters", currentYearData, "users"));
    let users = [];
    docs.forEach((doc) => {
      users.push(doc.data());
    });
    setUsers(users);
  }

  const getPlayers = async () => {
    const docs = await getDocs(collection(db, "masters", currentYearData, "players"));
    let players = [];
    docs.forEach((doc) => {
      players.push(doc.data());
    });
    players.sort((a,b) => a.last_name > b.last_name ? 1:-1);
    setPlayers(players);
  }

  const displayUsers = () => {
    return users.map((user, index) => <p id={index} key={index} className="masters-registered-user">{ user.firstName } { user.lastName }</p>);
  }
  const displayPlayerOptions = (tier, number=1) => {
    let tier_players;

    let SHOWING_ALL_PLAYERS_TO_SAVE_TIME = true;

    if (tier && !SHOWING_ALL_PLAYERS_TO_SAVE_TIME) {
      tier_players = players.filter(player => player.tier == tier);
    } else {
      tier_players = players;
      tier = 'wildcard'
    }
    return tier_players.map((player) => <option id={player.id + '-' + tier + '-' + number} value={player.id} key={player.id + '-' + tier + '-' + number} className="masters-player">{ player.first_name } { player.last_name }</option>);
  }

  // const listPlayer = (player) => {
  //   return <option key={player.id}>{ player.first_name } { player.last_name }</option>
  // }

  const saveUser = async () => {
    const fn = document.getElementById('fnInput');
    const ln = document.getElementById('lnInput');
    const wc = document.getElementById('wildcardInput');
    const t1 = document.getElementById('t1Input');
    const t2 = document.getElementById('t2Input');
    const t3 = document.getElementById('t3Input');
    const m1 = document.getElementById('m1Input');
    const m2 = document.getElementById('m2Input');
    const m3 = document.getElementById('m3Input');
    const ls = document.getElementById('lsInput');

    const docRef = await addDoc(collection(db, "masters", currentYearData, "users"), {
      "firstName": fn.value,
      "lastName": ln.value,
      "place": 0,
      "score": 0,
      "golfers" : {
        "wildcard": wc.value,
        "tier1": t1.value,
        "tier2": t2.value,
        "tier3": t3.value,
        "mid1": m1.value,
        "mid2": m2.value,
        "mid3": m3.value,
        "long": ls.value
      }
    });
    console.log('User created with ID: ', docRef.id)
    window.location.href = '/masters';
  }

  let today = Date.now();
  let deadline = new Date('April 11, 2024 10:30:00')

  if (today > deadline) {
    return (
      <div className="MastersNewUser">
        <NavBarMini alwaysShown={true} />
        <div className="masters-content">
          <div className="masters-header">
            <div className="masters-header-top">
              <a className="masters-type" href="https://www.masters.com/">The Masters</a>
              <p className="masters-type-secondary"></p>
              <p className="masters-title-standard">{ currentYear } 1A Masters Pool</p>
            </div>
          </div>

          <div className="masters-body">
            <h4>Registration is closed</h4>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }

  return (
    <div className="MastersNewUser">
      <NavBarMini alwaysShown={true} />
      <div className="masters-content">
        <div className="masters-header">
          <div className="masters-header-top">
            <a className="masters-type" href="https://www.masters.com/">The Masters</a>
            <p className="masters-type-secondary"></p>
            <p className="masters-title-standard">{ currentYear } 1A Masters Pool</p>
          </div>
        </div>

        <div className="masters-body">
          <div>
            <h4>Registered</h4>
            <div className="masters-registered-users">
              { displayUsers() }
            </div>
          </div>
          <div>
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="fnInput">First Name</label>
                <input type="text" className="form-control" id="fnInput"></input>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lnInput">Last Name</label>
                <input type="text" className="form-control" id="lnInput"></input>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor="inputState">Wildcard</label>
                <select id="wildcardInput" className="form-control">
                  <option>Choose Wildcard Player</option>
                  { displayPlayerOptions() }
                </select>
              </div>
            </div>
            <div className="form-group col-12">
              <label htmlFor="inputState">Tier 1</label>
              <select id="t1Input" className="form-control">
                <option>Choose Tier 1 Player</option>
                { displayPlayerOptions('tier1') }
              </select>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputState">Tier 2</label>
              <select id="t2Input" className="form-control">
                <option>Choose Tier 2 Player</option>
                { displayPlayerOptions('tier2') }
              </select>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputState">Tier 3</label>
              <select id="t3Input" className="form-control">
                <option>Choose Tier 3 Player</option>
                { displayPlayerOptions('tier3') }
              </select>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputState">Mid Tier 1</label>
              <select id="m1Input" className="form-control">
                <option>Choose Mid Tier Player #1</option>
                { displayPlayerOptions('midtier', '1') }
              </select>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputState">Mid Tier 2</label>
              <select id="m2Input" className="form-control">
                <option>Choose Mid Tier Player #2</option>
                { displayPlayerOptions('midtier', '2') }
              </select>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputState">Mid Tier 3</label>
              <select id="m3Input" className="form-control">
                <option>Choose Mid Tier Player #3</option>
                { displayPlayerOptions('midtier', '3') }
              </select>
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputState">Longshot</label>
              <select id="lsInput" className="form-control">
                <option>Choose Longshot Player</option>
                { displayPlayerOptions('longshot') }
              </select>
            </div>
            <button type="button" onClick={() => saveUser()} className="btn btn-primary masters-btn">Submit</button>
            </form>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default MastersNewUser;
