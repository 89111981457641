import React, { Component, useRef, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import NavBarMini from '../NavBarMini.js';
import Footer from '../Footer.js';
import LoadingAnimation from '../LoadingAnimation';

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';

const currentYear = '2024'
const currentYearData = 'data2024'

function updateData() {
  // get the updated data
  fetch(`https://www.masters.com/en_US/scores/feeds/${currentYear}/scores.json`)
    .then((response) => response.json())
    .then((data) => {
      let mastersData = data.data;

      // update metadata
      const dataRef = doc(db, "masters", currentYearData);
      updateDoc(dataRef, {
        currentRound: mastersData.currentRound,
        wallClockTime: mastersData.wallClockTime,
        statusRound: mastersData.statusRound
      })

      let players = mastersData.player;

      // merge player data
      players.forEach((player) => {
        const playerRef = doc(db, "masters", currentYearData, "players", player.id);
        setDoc(playerRef, {
          group: player.group,
          groupHistory: player.groupHistory,
          holeProgress: player.holeProgress,
          lastHoleWithShot: player.lastHoleWithShot,
          live: player.live,
          movement: player.movement,
          newStatus: player.newStatus,
          pos: player.pos,
          round1: player.round1,
          round2: player.round2,
          round3: player.round3,
          round4: player.round4,
          sort_order: player.sort_order,
          start: player.start,
          status: player.status,
          tee_order: player.tee_order,
          teetime: player.teetime,
          thru: player.thru,
          thruHistory: player.thruHistory,
          today: player.today,
          topar: player.topar,
          total: player.total,
          totalUnderPar: player.totalUnderPar
        }, { merge: true });
      })
    })
}


let defaultData = {
  currentRound: '1000',
  statusRound: 'NNNNN',
  wallClockTime: '0'
}

const MastersAugusta = () => {
  const [players, setPlayers] = useState([]);
  const [tournamentData, setTournamentData] = useState(defaultData);
  const [leaderboardReady, setLeaderboardReady] = useState(false);
  const [author, setAuthor] = useState({});
  const [datePublished, setDatePublished] = useState("");

  useEffect(() => {
    getPlayers();
    getTournamentData();
  }, [])

  const getPlayers = async () => {
    // TESTING
    // const docs = await getDocs(collection(db, "masters", "data", "players_test"));

    let players = [];

    // PRODUCTION
    fetch(`https://www.masters.com/en_US/scores/feeds/${currentYear}/scores.json`)
      .then((response) => response.json())
      .then((data) => {
        data.data.player.forEach((player) => {
          players.push(player);
        });
      })

    players = sortPlayers(players);
    setPlayers(players);
  }

  const getTournamentData = async () => {
    // TESTING

    // PRODUCTION
    const dataRef = doc(db, "masters", currentYearData);
    const dataDoc = await getDoc(dataRef);
    const originalData = dataDoc.data()

    // const dataRefXXXX = doc(db, "masters", "dataXXXX"); // replace XXXX with year of data
    // setDoc(dataRefXXXX, originalData); // save data from existing generic data

    setTournamentData(originalData);

    setLeaderboardReady(true);
  }

  function sortPlayers (players) {
    return players.sort((a,b) => Number(a.sort_order.split('|').filter(n => n).slice(-1)[0]) > Number(b.sort_order.split('|').filter(n => n).slice(-1)[0]) ? 1:-1);
  }

  // returns the correct round
  function currentScores (player) {
    let round = currentRoundToShow ();
    if (round == 1){
      return player.round1
    }
    else if (round == 2){
      return player.round2
    }
    else if (round == 3){
      return player.round3
    }
    else if (round == 4){
      return player.round4
    }
  }

  function currentRoundToShow () {
    let round = tournamentData.currentRound.indexOf('1');
    if (tournamentData.statusRound[round + 1] == 'N' || round == 0) {
      round++;
    }
    return round;
  }

  function playerTotalLeaderboard (player) {
    let topar = player.topar;
    let status = player.status;
    let round = currentRoundToShow();
    if (topar == null || topar == '' || topar == 'E') {
      topar = 0;
    }

    if (round > 2 && status == 'C') {
      return (
        <>
          <td className="masters-table-data total missed-cut"><span>MC</span></td>
          <td className="masters-table-data thru"><span></span></td>
        </>
      )
    } else if (status == 'W') {
      return (
        <>
          <td className="masters-table-data total withdrew"><span>WD</span></td>
          <td className="masters-table-data thru"><span></span></td>
        </>
      )
    }
    if (topar == 0 ) {
      return (
        <>
          <td className="masters-table-data total not-below-par"><span>0</span></td>
          <td className="masters-table-data thru"><span>{ player.thru }</span></td>
        </>
      )
    } else if (Number(topar) > 0) {
      let score = Number(topar)
      return (
        <>
          <td className="masters-table-data total not-below-par"><span>{ Math.abs(score) }</span></td>
          <td className="masters-table-data thru"><span>{ player.thru }</span></td>
        </>
      )
    } else {
      let score = Number(topar)
      return (
        <>
          <td className="masters-table-data total below-par"><span>{ Math.abs(score) }</span></td>
          <td className="masters-table-data thru"><span>{ player.thru }</span></td>
        </>
      )
    }
  }

  function playerScoreLeaderboard (score) {
    if (!Number.isInteger(score) && score == ''){
      return <td className="masters-table-data hole"><span></span></td>
    } else if (score == 0) {
      return <td className="masters-table-data hole not-below-par"><span>0</span></td>
    } else if (score > 0) {
      return <td className="masters-table-data hole not-below-par"><span>{ Math.abs(score) }</span></td>
    } else if (score < 0) {
      return <td className="masters-table-data hole below-par"><span>{ Math.abs(score) }</span></td>
    } else {
      return <td className="masters-table-data hole"><span></span></td>
    }
  }

  function playerScoreRowLeaderboard (player) {
    let status = player.status;
    let thru = player.thru;
    let round = currentRoundToShow();
    let roundData = currentScores(player);
    let roundScores = roundData.scores;
    let scores = [];

    console.log(status)
    console.log(round)


    if (round > 2 && status == 'C') {
      scores = [...Array(18)].map((e, hole) => <td className="masters-table-data hole missed-cut" key={player.id + "-" + (hole + 1)}><span>MC</span></td>);
      return scores;
    } else if (status == 'W') {
      scores = [...Array(18)].map((e, hole) => <td className="masters-table-data hole withdrew" key={player.id + "-" + (hole + 1)}><span>WD</span></td>);
      return scores;
    } else if (thru == '' && status != 'C') {
      scores = [...Array(18)].map((e, hole) => <td className="masters-table-data hole" key={player.id + "-" + (hole + 1)}><span></span></td>);
      return scores;
    }

    let prior = roundData.prior || 0;
    let roundScoresToPar;
    let runningTotal;
    if (thru.slice(-1) == '*') {
      roundScores = roundScores.slice(9,18).concat(roundScores.slice(0,9));
      roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - tournamentData.pars.round1[hole]));
      runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
      // runningTotal = runningTotal.slice(9,18).concat(runningTotal.slice(0,9));
    } else {
      roundScoresToPar = [prior].concat(roundScores.map((score, hole) => score - tournamentData.pars.round1[hole]));
      runningTotal = roundScoresToPar.map((sum => value => sum += value)(0)).slice(1)
    }


    if (thru.slice(-1) == '*') {
      let adjustedThru = Number(thru.slice(0, -1));

      if (adjustedThru < 9) {
        runningTotal = Array(9).fill('').concat(runningTotal.slice(0, adjustedThru)).concat(Array(9 - adjustedThru).fill(''))
      } else if(adjustedThru > 9) {
        runningTotal = runningTotal.slice(0, adjustedThru - 9).concat(Array(18 - adjustedThru).fill('')).concat(runningTotal.slice(9,18))
      } else {
        runningTotal = Array(9).fill('').concat(runningTotal.slice(9,18))
      }
      console.log(runningTotal.length)
    } else if (player.thru != '' && player.thru != 'F') {
      runningTotal = runningTotal.slice(0, player.thru).concat(Array(18 - player.thru).fill(''));
    }

    scores = runningTotal.map((score, hole) => playerScoreLeaderboard(score))
    return scores;
  }

  function displayPlayerRow (player) {
    return (
        <tr>
          <td className="masters-table-data-name">{ player.display_name.toUpperCase() }</td>
          { playerScoreRowLeaderboard(player) }
          { playerTotalLeaderboard(player) }
        </tr>
    );
  }

  if (leaderboardReady == false) {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <div className="masters-content">
          <div className="masters-header">
            <div className="masters-header-top">
              <a className="masters-type" href="https://www.masters.com/">The Masters</a>
              <p className={ "masters-title-standard" }>{ currentYear } 1A Masters Pool</p>
              <a className="masters-type" href="/masters">Pool Leaders</a>
            </div>
          </div>


          <div className="masters-body">
            <div className="masters-table-container">
              <div className="cut-off-half"><div className="masters-table-heading-title"><span>LEADERS</span></div></div>

              <table className="table masters-table">
                <thead className="masters-table-heading-hole">
                  <tr>
                    <th className="masters-table-heading-name"><span>HOLE</span></th>
                    <th className="masters-table-heading hole"><span>1</span></th>
                    <th className="masters-table-heading hole"><span>2</span></th>
                    <th className="masters-table-heading hole"><span>3</span></th>
                    <th className="masters-table-heading hole"><span>4</span></th>
                    <th className="masters-table-heading hole"><span>5</span></th>
                    <th className="masters-table-heading hole"><span>6</span></th>
                    <th className="masters-table-heading hole"><span>7</span></th>
                    <th className="masters-table-heading hole"><span>8</span></th>
                    <th className="masters-table-heading hole"><span>9</span></th>
                    <th className="masters-table-heading hole"><span>10</span></th>
                    <th className="masters-table-heading hole"><span>11</span></th>
                    <th className="masters-table-heading hole"><span>12</span></th>
                    <th className="masters-table-heading hole"><span>13</span></th>
                    <th className="masters-table-heading hole"><span>14</span></th>
                    <th className="masters-table-heading hole"><span>15</span></th>
                    <th className="masters-table-heading hole"><span>16</span></th>
                    <th className="masters-table-heading hole"><span>17</span></th>
                    <th className="masters-table-heading hole"><span>18</span></th>
                  </tr>
                </thead>
                <thead className="masters-table-heading-par">
                <tr>
                  <th className="masters-table-heading-name"><span>PAR</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                </tr>
                </thead>
                <thead className="masters-table-heading-total">
                <tr>
                <th className="masters-table-heading-name"><span>NAME</span></th>
                <th className="masters-table-heading"><span>TOTAL</span></th>
                <th className="masters-table-heading"><span>THRU</span></th>
                </tr>
                </thead>
                <tbody>
                  { players.map((player) => displayPlayerRow(player)) }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default MastersAugusta;
