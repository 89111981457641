import React, { Component } from 'react';
import NavBar from './NavBar.js';
import NavBarMini from './NavBarMini.js';

let GAMEBOARD_ROWS = 20;
let GAMEBOARD_COLS = 50;

if (window.innerWidth < 740) {
  GAMEBOARD_ROWS = 20;
  GAMEBOARD_COLS = 20;
}

function createGameboard(random_limit = .7) {
  let gameboard = [];
  let gameboard_line = [];
  for(let i = 0; i < GAMEBOARD_ROWS; i++) {
    gameboard_line = [];
    for(let j = 0; j < GAMEBOARD_COLS; j++) {
      let k = Math.random();
      if(k > random_limit) {
        gameboard_line.push('X')
      } else {
        gameboard_line.push(' ')
      }
    }
    gameboard.push(gameboard_line)
  }

  return gameboard
}
function updateGameboard(gameboard) {
  var newGameboard = [];
  var newGameboardLine = [];
  for(let row = 0; row < GAMEBOARD_ROWS; row++) {
    newGameboardLine = [];
    let neighbors = 0;
    let state = ' ';
    for(let col = 0; col < GAMEBOARD_COLS; col++) {
      state = gameboard[row][col];
      neighbors = 0;

      if (row - 1 >= 0){
        if(gameboard[row-1][col] === 'X'){
          neighbors++;
        }
        if(col - 1 >= 0) {
          if(gameboard[row-1][col-1] === 'X'){
            neighbors++;
          }
        }
        if(col + 1 < GAMEBOARD_COLS) {
          if(gameboard[row-1][col+1] === 'X'){
            neighbors++;
          }
        }
      }
      if (row + 1 < GAMEBOARD_ROWS){
        if(gameboard[row+1][col] === 'X'){
          neighbors++;
        }
        if(col - 1 >= 0) {
          if(gameboard[row+1][col-1] === 'X'){
            neighbors++;
          }
        }
        if(col + 1 < GAMEBOARD_COLS) {
          if(gameboard[row+1][col+1] === 'X'){
            neighbors++;
          }
        }
      }
      if(col - 1 >= 0) {
        if(gameboard[row][col-1] === 'X'){
          neighbors++;
        }
      }
      if(col + 1 < GAMEBOARD_COLS) {
        if(gameboard[row][col+1] === 'X'){
          neighbors++;
        }
      }

      if(state === 'X') {
        if(neighbors < 2){
          newGameboardLine.push(' ');
        } else if (neighbors > 3) {
          newGameboardLine.push(' ');
        } else {
          newGameboardLine.push('X');
        }
      } else if (neighbors === 3) {
        newGameboardLine.push('X');
      } else {
        newGameboardLine.push(' ');
      }
    }
    newGameboard.push(newGameboardLine);
  }

  return newGameboard
}

function gameDone(gameboard, newGameboard) {
  let values = gameboard.flat();
  return !values.includes('X') || gameboard == newGameboard;
}

class GameOfLife extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      gameboard: createGameboard(),
      paused: true
    }
  }

  clearGameboard() {
    let gameboard = createGameboard(2);
    this.setState({ gameboard: gameboard });
    this.setState({ count: 0 });
  }

  randomGameboard() {
    let gameboard = createGameboard();
    this.setState({ gameboard: gameboard });
    this.setState({ count: 0 });
  }

  flipSpace(row, col) {
    let gameboard = this.state.gameboard;
    let state = gameboard[row][col];
    if(state === 'X') {
      gameboard[row][col] = ' ';
    } else {
      gameboard[row][col] = 'X';
    }
    this.setState({ gameboard: gameboard });
    this.setState({ count: 0 });
  }

  playpause() {
    let playPauseButton = document.getElementById('playPauseButton')
    if (this.state.paused) {
      this.setState({ paused: false });
      playPauseButton.innerText = 'Pause';
      playPauseButton.style.marginRight = '0px';
    } else {
      this.setState({ paused: true });
      playPauseButton.innerText = 'Play';
      playPauseButton.style.marginRight = '13.81px';
    }
  }

  pause() {
    let playPauseButton = document.getElementById('playPauseButton')

    this.setState({ paused: true });
  }

  render(){
    return (
      <div className="GameOfLife">
        <NavBar />
        <NavBarMini />

        <div className="game-of-life-content">
          <div className="game-of-life-header">
            <a className="game-of-life-header-title" target="_blank" href="https://en.wikipedia.org/wiki/Conway%27s_Game_of_Life">Game of Life</a>

            <div className="game-of-life-header-buttons">
              <button id="playPauseButton" onClick={() => this.playpause()}>Play</button>
              <button onClick={() => this.clearGameboard()}>Clear</button>
              <button onClick={() => this.randomGameboard()}>Random</button>
            </div>
            <h4 className="game-of-life-header-count">Generations: { this.state.count }</h4>
          </div>

          <div className="game-of-life-body">
            { this.state.gameboard.map((line, row) => (
              <div className="game-of-life-line"> {
                line.map((space, col)  => (
                  <span className="game-of-life-space unselectable" onClick={() => this.flipSpace(row, col)} row={row} col={col}>{space}</span>
                ))}
              </div>
            ))}
          </div>
        </div>

      </div>
    );
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if(!this.state.paused) {
        let newGameboard = updateGameboard(this.state.gameboard);

        if(gameDone(this.state.gameboard, newGameboard)) {
          this.playpause();
        } else {
          this.setState({ count: this.state.count + 1 });
          this.setState({ gameboard: newGameboard });
        }
      }
    }, 500);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
}

export default GameOfLife;
