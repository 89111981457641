import React from 'react';

const MainContent = () => {
  return (
    <div className="MainContent">
      <div className="main-column-left">
        <div className="column-section">
          <div className="primary-story-container">
            <div className="primary-story-content-container">
              <div className="primary-story-left-column">
                <div className="primary-story-section">
                  <h5 className="primary-story-subtitle article-subtitle">How The Limits Of The Digital World Are Rapidly Changing</h5>
                  <ul className="primary-story-summaries">
                    <li className="primary-story-summary article-intro">
                      AI algorithms are transforming how we navigate the internet.
                    </li>
                    <li className="primary-story-summary">
                      From tailored content suggestions to targeted ads, our experiences online are shaped mostly by A.I.
                    </li>
                    <li className="primary-story-summary">
                      Concerns arise regarding privacy, bias, and the ethical implications of A.I.-driven decisions.
                    </li>
                  </ul>

                </div>
                <div className="primary-story-section">
                  <p className="primary-story-offshoot bold">
                    AI Ethics in the Spotlight: Navigating the Moral Imperatives of Technological Advancement
                  </p>
                </div>
                <div className="primary-story-section">
                  <p className="primary-story-offshoot bold">
                    How Machine Learning is Shaping Industries and Communities
                  </p>
                </div>
              </div>
              <div className="primary-story-right-column">
                <div className="primary-story-image-container">
                  <img className="primary-story-image" src="https://static01.nyt.com/images/2024/02/15/upshot/14raman/14raman-articleLarge.png?quality=75&auto=webp&disable=upscale"></img>
                  <div className="primary-story-image-footnote">
                    <p className="primary-story-image-text">
                      <span className="primary-story-image-credit"/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-section">
          <div className="column-section-2">
            <div span="7" className="column-section-1-of-2">
              <div className="story-image-container">
                <img className="story-image" src="https://i1.nyt.com/images/2016/02/24/dining/24CEREALHIRES12/24CEREALHIRES12-master675.jpg"></img>
                <div className="story-image-footnote">
                  <p className="story-image-text">
                    <span className="story-image-credit"/>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h3 className="primary-story-title">When Did We Stop Eating Cereal?</h3>
                </div>
                <p className="primary-story-summary">Delve into the decline of cereal consumption in American households, exploring shifting breakfast habits and the rise of alternative morning options.</p>
              </div>
            </div>
            <div span="7" className="column-section-1-of-2">
            <div className="story-image-container">
                <img className="story-image" src="https://static01.nyt.com/images/2023/01/01/opinion/01altman-image/01altman-image-mobileMasterAt3x.jpg?quality=75&auto=webp&disable=upscale&width=600"></img>
                <div className="story-image-footnote">
                  <p className="story-image-text">
                    <span className="story-image-credit"/>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h3 className="primary-story-title">The Growing Divide Between Short Kings and Tall Fellas</h3>
                </div>
                <p className="primary-story-summary">Examine societal perceptions and challenges faced by shorter men in various aspects of life, shedding light on height discrimination and its implications for personal and professional experiences.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="column-section">
          <div className="column-section-2">
            <div className="column-section-1-of-2">
              <div className="story-image-container">
                <img className="story-image" src="https://static01.nyt.com/images/2023/07/24/crosswords/24gameplay-scrabble-strategy/24gameplay-scrabble-strategy-articleLarge.jpg?quality=75&auto=webp&disable=upscale"></img>
                <div className="story-image-footnote">
                  <p className="story-image-text">
                    <span className="story-image-credit"/>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h3 className="primary-story-title">Why Big Brains Prefer Scrabble To Chess</h3>
                </div>
                <p className="primary-story-summary">Chess scholars are trying to figure out how they can be more like Scrabble savants. Initial attemps have gone disastrously.</p>
              </div>
            </div>
            <div className="column-section-1-of-2">
            <div className="story-image-container">
                <img className="story-image" src="https://www.respectmyregion.com/wp-content/uploads/2023/05/tim-robinson-2.jpg"></img>
                <div className="story-image-footnote">
                  <p className="story-image-text">
                    <span className="story-image-credit"/>
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h3 className="primary-story-title">I Stopped Honking Last Year. Now I'm Better Than You.</h3>
                </div>
                <p className="primary-story-summary">Explore the choice to abstain from using the car horn, that reveals insights into urban noise dynamics and the impact of personal choices on daily interactions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-column-right">
        <div className="column-section">
          <div>
            <div className="story-image-container">
              <img className="story-image" src="https://static01.nyt.com/images/2019/11/17/opinion/sunday/17wellons/00wellons-articleLarge.jpg?quality=75&auto=webp&disable=upscale"></img>
              <div className="story-image-footnote">
                <p className="story-image-text">
                  <span className="story-image-credit"/>
                </p>
              </div>
            </div>
            <p className="secondary-story-title">This Company Is Like Private Jets For Cats</p>
            <p className="secondary-story-summary">Find out why more airplane passengers have been suffering from allergies and what this comapny is doing to fix it.</p>
          </div>
        </div>
        <div className="column-section">
          <div>
            <div className="story-image-container">
              <img className="story-image" src="https://static01.nyt.com/images/2023/07/05/multimedia/03Sandwiches1-bwqk/03Sandwiches1-bwqk-articleLarge.jpg?quality=75&auto=webp&disable=upscale"></img>
              <div className="story-image-footnote">
                <p className="story-image-text">
                  <span className="story-image-credit"/>
                </p>
              </div>
            </div>
            <p className="secondary-story-title">The Health Benefits Of A Tasty Sandwich</p>
            <p className="secondary-story-summary">Your neighbor is eating a lot more sandwiches than they used to. This is why.</p>
          </div>
        </div>
        <div className="column-section">
        <div>
            <div className="story-image-container">
              <img className="story-image" src="https://static01.nyt.com/images/2022/02/13/opinion/07Kang-image/07Kang-image-videoSixteenByNineJumbo1600.jpg"></img>
              <div className="story-image-footnote">
                <p className="story-image-text">
                  <span className="story-image-credit"/>
                </p>
              </div>
            </div>
            <p className="secondary-story-title">Opinion | The Legalized Gambling Free-for-All</p>
            <p className="secondary-story-summary">The surge in abusive behavior targeting college athletes and professional players has drawn attention from coaches and the players themselves.</p>
          </div>
        </div>
      </div>

    </div>
  );
  return (
    <div className="MainContent">
      <div className="main-column-left">
        <div className="column-section">
          <div className="primary-story-container">
            <div className="primary-story-title-container">
              <h3 className="primary-story-title article-title">How The Internet Changed Everything</h3>
            </div>
            <div className="primary-story-content-container">
              <div className="primary-story-left-column">
                <div className="primary-story-section">
                  <h5 className="primary-story-subtitle article-subtitle">How The Limits Of The Digital World Are Rapidly Changing</h5>
                  <ul className="primary-story-summaries">
                    <li className="primary-story-summary article-intro">
                      AI algorithms are transforming how we navigate the internet.
                    </li>
                    <li className="primary-story-summary">
                      From tailored content suggestions to targeted ads, our experiences online are shaped mostly by A.I.
                    </li>
                    <li className="primary-story-summary">
                      Concerns arise regarding privacy, bias, and the ethical implications of A.I.-driven decisions.
                    </li>
                  </ul>

                </div>
                <div className="primary-story-section">
                  <p className="primary-story-offshoot bold">
                    AI Ethics in the Spotlight: Navigating the Moral Imperatives of Technological Advancement
                  </p>
                </div>
                <div className="primary-story-section">
                  <p className="primary-story-offshoot bold">
                    How Machine Learning is Shaping Industries and Communities
                  </p>
                </div>
              </div>
              <div className="primary-story-right-column">
                <div className="primary-story-image-container">
                  <img className="primary-story-image" src="https://static01.nyt.com/images/2024/02/15/upshot/14raman/14raman-articleLarge.png?quality=75&auto=webp&disable=upscale"></img>
                  <div className="primary-story-image-footnote">
                    <p className="primary-story-image-text">
                      <span className="primary-story-image-credit"/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-section">
          <div>
            <div>
              <h3 className="primary-story-title">The Rigid World of French Cheesemaking Meets Unbound Climate Change</h3>
            </div>
            <p className="primary-story-summary">Fromage Under Fire: French Cheesemaking Grapples with Climate Challenges</p>
            <p className="primary-story-summary">How Changing Weather Patterns Threaten French Cheese Heritage</p>
            <p className="primary-story-summary">Molding Tradition: Adaptation Strategies for French Cheesemakers in a Warming World</p>
            <p className="primary-story-summary">Brie-lliant Solutions: Innovations in Sustainable Cheesemaking Amidst Climate Concerns</p>
            <p className="primary-story-summary">Aged to Perfection, Tested by Climate: The Future of French Cheesemaking in Uncertain Times</p>
          </div>
        </div>
        <div className="column-v">
          <div>
            <p className="primary-story-summary">The Art of Men's Bespoke Fashion in the Digital Age</p>
            <p className="primary-story-summary">From the Runway to the Streets: Decoding the Latest Styles for Modern Men</p>
            <p className="primary-story-summary">Exploring Evolving Trends in Men's Fashion</p>
            <p className="primary-story-summary">Redefining Elegance and Individuality in Contemporary Men's Wardrobes</p>
            <p className="primary-story-summary">Challenging Gender Norms Through Men's Fashion Expression</p>
          </div>
        </div>
      </div>
      <div className="main-column-right">
        <div className="column-section">
          <div>
            <p className="secondary-story-summary">When Did We Stop Eating Cereal?</p>
            <p className="secondary-story-summary">Is It Time We Ditch Our Cell Phones?</p>
            <p className="secondary-story-summary">How I Built My Own Bicycle</p>
            <p className="secondary-story-summary">Stop Throwing Away Your Milk Jugs</p>
          </div>
        </div>
        <div className="column-section">
          <div>
            <p className="secondary-story-summary">Why The Broken Stool In My Kitchen Won't Be Fixed Soon</p>
            <p className="secondary-story-summary">The Health Benefits Of A Tasty Sandwich</p>
            <p className="secondary-story-summary">The Growing Divide Between Short Kings and Tall Fellas</p>
            <p className="secondary-story-summary">I Stopped Honking Last Year. Now I'm Better Than You.</p>
          </div>
        </div>
        <div className="column-section">
          <div>
            <p className="secondary-story-summary">Why Big Brains Prefer Scrabble To Chess</p>
            <p className="secondary-story-summary">A Divided Country. Except For Live Music.</p>
            <p className="secondary-story-summary">And Then There Were Two: My Brain Cells</p>
            <p className="secondary-story-summary">This Company Is Like Private Jets For Cats</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MainContent
