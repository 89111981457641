import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  return (
    <div className="Footer">
      <div className="article-footer">

        <ul className="footer-copyright">
          <li><a href="https://chandlercrane.com">©&nbsp;<span>2023</span>&nbsp;<span>The Chandler Crane Company</span></a></li>

        </ul>
        <ul className="footer-links">
          <li><a className="footer-link" href="https://www.nytco.com/">NYTCo</a></li>
          <li><a className="footer-link" href="https://help.nytimes.com/hc/en-us/articles/115015385887-Contact-Us">Contact Us</a></li>
          <li><a className="footer-link" href="https://help.nytimes.com/hc/en-us/articles/115015727108-Accessibility">Accessibility</a></li>
          <li><a className="footer-link" href="https://www.nytco.com/careers/">Work with us</a></li>
          <li><a className="footer-link" href="https://nytmediakit.com/">Advertise</a></li>
          <li><a className="footer-link" href="https://www.tbrandstudio.com/">T Brand Studio</a></li>
          <li><a className="footer-link" href="https://www.nytimes.com/privacy/cookie-policy#how-do-i-manage-trackers">Your Ad Choices</a></li>
          <li><a className="footer-link" href="https://www.nytimes.com/privacy/privacy-policy">Privacy Policy</a></li>
          <li><a className="footer-link" href="https://help.nytimes.com/hc/en-us/articles/115014893428-Terms-of-service">Terms of Service</a></li>
          <li><a className="footer-link" href="https://help.nytimes.com/hc/en-us/articles/115014893968-Terms-of-sale">Terms of Sale</a></li>
          <li><a className="footer-link" href="/sitemap/">Site Map</a></li>
          <li><a className="footer-link" href="https://www.nytimes.com/ca/?action=click&amp;region=Footer&amp;pgtype=Homepage">Canada</a></li>
          <li><a className="footer-link" href="https://www.nytimes.com/international/?action=click&amp;region=Footer&amp;pgtype=Homepage">International</a></li>
          <li><a className="footer-link" href="https://help.nytimes.com/hc/en-us">Help</a></li>
          <li><a className="footer-link" href="https://www.nytimes.com/subscription?campaignId=37WXW">Subscriptions</a></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer
