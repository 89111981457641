import React, { Component } from 'react';

function getColorTheme() {
  return 'bw'
}

class ColorPalette extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorTheme: getColorTheme()
    };
  }

  render(){
    return(
      <div className="color-palette">
        <div id="colorMenu">
          <button className="color-menu-btn" id="color-theme-palette-menu" onClick={openPalette}>🎨</button>
        </div>
        <div className="hidden" id="paintPalette">
          <button className="color-theme-btn" id="color-theme-palette-bw" onClick={changeColorTheme}>🐄</button>
          <button className="color-theme-btn" id="color-theme-palette-dark" onClick={changeColorTheme}>🌙</button>
          <button className="color-theme-btn" id="color-theme-palette-red" onClick={changeColorTheme}>🧰</button>
          <button className="color-theme-btn" id="color-theme-palette-orange" onClick={changeColorTheme}>🔥</button>
          <button className="color-theme-btn" id="color-theme-palette-yellow" onClick={changeColorTheme}>🧀</button>
          <button className="color-theme-btn" id="color-theme-palette-green" onClick={changeColorTheme}>☘️</button>
          <button className="color-theme-btn" id="color-theme-palette-blue" onClick={changeColorTheme}>🦋</button>
          <button className="color-theme-btn" id="color-theme-palette-purple" onClick={changeColorTheme}>🔮</button>
          <button className="color-theme-btn" id="color-theme-palette-bluered" onClick={changeColorTheme}>💈</button>
          <button className="color-theme-btn" id="color-theme-palette-greenyellow" onClick={changeColorTheme}>🛺</button>
          <button className="color-theme-btn" id="color-theme-palette-bluegreen" onClick={changeColorTheme}>🌎</button>
        </div>
      </div>
    );
  }
  // componentDidMount() {
  //   this.interval = setInterval(() => this.setState({time: getTime()}), 1000);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }
}

function changeColorTheme(event) {
  let theme = event.target.id.split('-').pop();
  console.log(theme);

  let __newspaper = '#ffffd4';
  let __divider_light = '#C7C7C7'
  let __divider_dark = '#000000'
  let __computer_blue = 'blue';

  let __black = '#000000';
  let __black_accent = '#1a1a1a';
  let __mid_black = '#5a5a5a';
  let __light_black = '#9a9a9a';
  let __white_accent = '#e6e6e6';
  let __white = '#ffffff';

  let __light_grey = '#ebebeb';
  let __mid_grey = '#d6d6d6';
  let __dark_grey = '#c2c2c2';

  let __deep_blue1 = '#002537';
  let __deep_blue2 = '#003047';
  let __deep_blue3 = '#003c59';
  let __deep_blue4 = '#004c70';

  let __red = '#e91733';
  let __light_red = '#f96d78';
  let __orange = '#ff6633';
  let __light_orange = '#ffa24b';
  let __yellow = '#ffda00';
  let __light_yellow = '#fcdc6a';
  let __green = '#20af41';
  let __light_green = '#a7d89c';
  let __blue = '#207ae9';
  let __light_blue = '#55aee9';
  let __purple = '#965ee9';
  let __light_purple = '#af89e9';

  if(theme == 'bw') {
    document.documentElement.style.setProperty('--color-primary', __black);
    document.documentElement.style.setProperty('--color-primary-hover', __light_black);
    document.documentElement.style.setProperty('--color-secondary', __mid_black);
    document.documentElement.style.setProperty('--background', __white);
    document.documentElement.style.setProperty('--divider-light', __divider_light);
    document.documentElement.style.setProperty('--divider-dark', __divider_dark);
    document.documentElement.style.setProperty('--nav-divider-top', __light_grey);
    document.documentElement.style.setProperty('--nav-divider-bottom', __black);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'red') {
    document.documentElement.style.setProperty('--color-primary', __red);
    document.documentElement.style.setProperty('--color-primary-hover', __light_red);
    document.documentElement.style.setProperty('--color-secondary', __light_red);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __light_red);
    document.documentElement.style.setProperty('--divider-dark', __light_red);
    document.documentElement.style.setProperty('--nav-divider-top', __light_red);
    document.documentElement.style.setProperty('--nav-divider-bottom', __light_red);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'orange') {
    document.documentElement.style.setProperty('--color-primary', __orange);
    document.documentElement.style.setProperty('--color-primary-hover', __light_orange);
    document.documentElement.style.setProperty('--color-secondary', __light_orange);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __light_orange);
    document.documentElement.style.setProperty('--divider-dark', __light_orange);
    document.documentElement.style.setProperty('--nav-divider-top', __light_orange);
    document.documentElement.style.setProperty('--nav-divider-bottom', __light_orange);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'yellow') {
    document.documentElement.style.setProperty('--color-primary', __yellow);
    document.documentElement.style.setProperty('--color-primary-hover', __light_yellow);
    document.documentElement.style.setProperty('--color-secondary', __light_yellow);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __light_yellow);
    document.documentElement.style.setProperty('--divider-dark', __light_yellow);
    document.documentElement.style.setProperty('--nav-divider-top', __light_yellow);
    document.documentElement.style.setProperty('--nav-divider-bottom', __light_yellow);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'green') {
    document.documentElement.style.setProperty('--color-primary', __green);
    document.documentElement.style.setProperty('--color-primary-hover', __light_green);
    document.documentElement.style.setProperty('--color-secondary', __light_green);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __light_green);
    document.documentElement.style.setProperty('--divider-dark', __light_green);
    document.documentElement.style.setProperty('--nav-divider-top', __light_green);
    document.documentElement.style.setProperty('--nav-divider-bottom', __light_green);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'blue') {
    document.documentElement.style.setProperty('--color-primary', __blue);
    document.documentElement.style.setProperty('--color-primary-hover', __light_blue);
    document.documentElement.style.setProperty('--color-secondary', __light_blue);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __light_blue);
    document.documentElement.style.setProperty('--divider-dark', __light_blue);
    document.documentElement.style.setProperty('--nav-divider-top', __light_blue);
    document.documentElement.style.setProperty('--nav-divider-bottom', __light_blue);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'purple') {
    document.documentElement.style.setProperty('--color-primary', __purple);
    document.documentElement.style.setProperty('--color-primary-hover', __light_purple);
    document.documentElement.style.setProperty('--color-secondary', __light_purple);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __light_purple);
    document.documentElement.style.setProperty('--divider-dark', __light_purple);
    document.documentElement.style.setProperty('--nav-divider-top', __light_purple);
    document.documentElement.style.setProperty('--nav-divider-bottom', __light_purple);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'dark') {
    document.documentElement.style.setProperty('--color-primary', __deep_blue4);
    document.documentElement.style.setProperty('--color-primary-hover', __mid_grey);
    document.documentElement.style.setProperty('--color-secondary', __deep_blue4);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __deep_blue3);
    document.documentElement.style.setProperty('--divider-dark', __deep_blue3);
    document.documentElement.style.setProperty('--nav-divider-top', __deep_blue3);
    document.documentElement.style.setProperty('--nav-divider-bottom', __deep_blue2);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'bluered') {
    document.documentElement.style.setProperty('--color-primary', __blue);
    document.documentElement.style.setProperty('--color-primary-hover', __light_blue);
    document.documentElement.style.setProperty('--color-secondary', __light_red);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __red);
    document.documentElement.style.setProperty('--divider-dark', __red);
    document.documentElement.style.setProperty('--nav-divider-top', __red);
    document.documentElement.style.setProperty('--nav-divider-bottom', __red);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'greenyellow') {
    document.documentElement.style.setProperty('--color-primary', __green);
    document.documentElement.style.setProperty('--color-primary-hover', __light_green);
    document.documentElement.style.setProperty('--color-secondary', __light_yellow);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __yellow);
    document.documentElement.style.setProperty('--divider-dark', __yellow);
    document.documentElement.style.setProperty('--nav-divider-top', __yellow);
    document.documentElement.style.setProperty('--nav-divider-bottom', __yellow);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  } else if (theme == 'bluegreen') {
    document.documentElement.style.setProperty('--color-primary', __blue);
    document.documentElement.style.setProperty('--color-primary-hover', __light_blue);
    document.documentElement.style.setProperty('--color-secondary', __light_green);
    document.documentElement.style.setProperty('--background', __deep_blue1);
    document.documentElement.style.setProperty('--divider-light', __green);
    document.documentElement.style.setProperty('--divider-dark', __green);
    document.documentElement.style.setProperty('--nav-divider-top', __green);
    document.documentElement.style.setProperty('--nav-divider-bottom', __green);
    document.documentElement.style.setProperty('--game-of-life-background', __computer_blue);
    document.documentElement.style.setProperty('--game-of-life-primary', __white);
    document.documentElement.style.setProperty('--game-of-life-hover', __yellow);
  }
}

function openPalette(event) {
  let paintPalette = document.getElementById('paintPalette')
  if(paintPalette.classList.contains('hidden')) {
    paintPalette.classList.remove('hidden')
  } else {
    paintPalette.classList.add('hidden')
  }
}
function closePalette() {
  console.log('trying to close palette');
  let paintPalette = document.getElementById('paintPalette')
  paintPalette.classList.add('hidden')
}
document.addEventListener("onload", function(){
  // put in logic to close the palette menu when another part of the screen is clicked or hovered...
  // let paintPalette = document.getElementById('paintPalette')
  // paintPalette.addEventListener("click", function(){
  //   closePalette()
  // })
})

export default ColorPalette;
