import React, { Component, useState, useEffect } from 'react';
import NavBar from './NavBar.js';
import NavBarMini from './NavBarMini.js';

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const ACCOUNT = 'chandlercrane'
const DAYS_THIS_WEEK = new Date().getDay() + 1;
const WEEKS_TO_CHART = 10

function getColorByCount(count) {
  if (count < .2) {
    return 'drink-chart-day-color-1';
  } else if (count >= .2 && count < .4) {
    return 'drink-chart-day-color-2';
  } else if (count >= .4 && count < .6) {
    return 'drink-chart-day-color-3';
  } else if (count >= .6 && count < .8) {
    return 'drink-chart-day-color-4';
  } else {
    return 'drink-chart-day-color-5';
  }
}

function calculateDaysAgo(week, day) {

  if (week == 0) {
    return DAYS_THIS_WEEK - day - 1;
  } else {
    return DAYS_THIS_WEEK + (week-1)*7 + (6-day)
  }
}

function populateDrinkChart(untappdData) {
  let drinkChart = Array(WEEKS_TO_CHART);

  drinkChart[0] = Array(DAYS_THIS_WEEK);
  for (var week = 0; week < drinkChart.length; week++) {
    if (week > 0) {
      drinkChart[week] = Array(7);
    }
    for (var day = 0; day < drinkChart[week].length; day++) {
      let count = Math.random(10);
      drinkChart[week][day] = {
        days_ago: calculateDaysAgo(week, day),
        count: count,
        color: getColorByCount(count)
      }
    }
  }

  return drinkChart;
}

function createDrinkChart () {
  // let untappdData = getUntappdData();
  getDrinkData();
}

async function setDataInFirebase(data) {
  let checkins = data.response.beers
  for(let i = 0; i < checkins.count; i++) {
    // create new checkin...
    let checkin = data.response.beers.items[i]
    console.log(checkin)
    await setDoc(doc(db, "checkins", checkin.first_checkin_id.toString()), checkin);
    await setDoc(doc(db, "beers", checkin.beer.bid.toString()), checkin.beer);
    await setDoc(doc(db, "breweries", checkin.brewery.brewery_id.toString()), checkin.brewery);
  }
  console.log('done')
  // console.log('in this function:')
  // console.log(data)
}

async function getUntappdData() {
  // let call_num = 1
  // let data = '';
  //
  // let CLIENTID = '63F2B75B02EA6114311839C75AD7439D8484A7F4';
  // let CLIENTSECRET = '3CD1A71A91FC33AA8C935BC5A4F153A325521758';
  // let URL = 'https://api.untappd.com/v4/user/beers/'+ ACCOUNT + '?limit=50&&offset=1200&client_id=' + CLIENTID + '&client_secret=' + CLIENTSECRET;
  // let next_url = URL;
  // fetch(next_url)
  //   .then((response) => response.json())
  //   .then(
  //     (data) => {
  //       setDataInFirebase(data);
  //       try {
  //         next_url = data.response.pagination.next_url + '&client_id=' + CLIENTID + '&client_secret=' + CLIENTSECRET;
  //       } catch {
  //         next_url = '';
  //       }
  //       call_num++;
  //       console.log(next_url);
  //   })

}


async function getDrinkData() {
  const querySnapshot = await getDocs(collection(db, "checkins"));
  let num = 0
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    num += 1
    //console.log(doc.data().beer.beer_name)
    //console.log(doc.id, " => ", doc.data());
  });
  console.log('number of checkins', num)
}

//createDrinkChart();

class Drink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drinks: [],
    }
  }

  render(){
    return (
      <div className="Drink">
        <NavBar />
        <NavBarMini />

        <div className="page-content">
          <div className="page-header">
            <h4 className="page-header-title">Drinks</h4>
            {/*<ul>
              <li>Center day of week text</li>
              <li>Add Month label</li>
              <li>Rearrange chart as one big list, left to right, with row max of 7</li>
              <li>Pull in untappd data for last X days, create chart with counts defaulted to 0, then populate fields</li>
            </ul>*/}
          </div>
          <div className="page-body">

            { this.state.drinks.map((week, week_idx) => (
              <div className="drink-chart-week"> {
                week.map((day, day_idx) => (
                  <span className={"drink-chart-day unselectable " + day.color} week={week_idx} day={day_idx}>{day.days_ago}</span>
                ))}
              </div>
            ))}

            { /*
            <div className="drink-chart-week">
              <span className="day-label unselectable">S</span>
              <span className="day-label unselectable">M</span>
              <span className="day-label unselectable">T</span>
              <span className="day-label unselectable">W</span>
              <span className="day-label unselectable">T</span>
              <span className="day-label unselectable">F</span>
              <span className="day-label unselectable">S</span>
            </div>
            */}

          </div>
        </div>

      </div>
    );
  }
}

export default Drink;
