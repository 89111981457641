import React, { Component } from 'react';
import NavBar from './NavBar.js';


class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: 'chandlercrane'
    }
  }

  render(){
    return (
      <div className="Games">
        <NavBar />
        <div className="games-header">
          <h4 className="games-header-title">Games</h4>
          <a href="games/tichu">Tichu</a>
        </div>
      </div>
    );
  }

  // componentDidMount() {
  //   this.interval = setInterval(() => {
  //     if(!this.state.paused) {
  //       let newGameboard = updateGameboard(this.state.gameboard);
  //       if(gameDone(this.state.gameboard, newGameboard)) {
  //         this.setState({ paused: true });
  //       } else {
  //         this.setState({ gameboard: newGameboard });
  //       }
  //     }
  //   }, 500);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }
}

export default Games;
