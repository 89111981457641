import React, { Component, useRef, useState, useEffect } from 'react';

import LoadingAnimation from '../LoadingAnimation.js';
import * as MastersUtils from './MastersUtils.js';
import NavBarMini from '../NavBarMini.js';
import Footer from '../Footer.js';

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase.js';

const currentYear = '2024'
const currentYearData = 'data2024'

const defaultMastersData = {
  currentRound: '1000',
  statusRound: 'NNNNN',
  wallClockTime: '0',
  player: [],
  yardages: [],
  pars: []
}

const MastersRefactor = () => {
  const [leaderboardReady, setLeaderboardReady] = useState(false);
  const [masters, setMasters] = useState(defaultMastersData);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
    getMastersData();

    setLeaderboardReady(true);
  }, [])

  // Fetch users from Firebase DB
  const getUsers = async () => {
    const docs = await getDocs(collection(db, "masters", currentYearData, "users"));

    let users = [];
    docs.forEach((doc) => {
      const userID = doc.id;
      const user = doc.data();
      user.id = userID;
      users.push(user);
    });

    setUsers(users);
  }

  // Fetch Masters data from API endpoint
  const getMastersData = async () => {
    fetch(`https://www.masters.com/en_US/scores/feeds/${currentYear}/scores.json`)
      .then((response) => response.json())
      .then((data) => {
        setMasters(data.data);
      })
  }

  function displayUserAndPlayers (user, place) {
    return (
      <>
        { displayUser(user, place) }
        { displayPlayersForUser(user) }
      </>
    )
  }
  function displayUser (user, place) {
    let rowClassList = ['clickable'];
    if (MastersUtils.tournamentEnded(masters) && place === 0) {
      rowClassList.push('winner');
    }

    return (
      <tr id={user.lastName + "-user-row"} className={ rowClassList.join(' ') } onClick={() => MastersUtils.showPlayers(user.lastName + "-user-row")}>
        <td className="masters-table-data-name user-name">{user.lastName.toUpperCase() }</td>
        { userHoles(user) }
        { MastersUtils.userGrossScore(user) }
        { MastersUtils.userNetScore(user) }
      </tr>
    );
  }
  function userHoles (user) {
    let roundNumber = MastersUtils.getRoundNumber(masters)
    let [playerScores, maxThru] = MastersUtils.getPlayerHoleScores(user.playersSorted.slice(0,5), roundNumber, masters.pars.round1)
    let userScore = playerScores.reduce((a, b) => a.map((v, i) => v + b[i]));

    userScore = userScore.slice(0, maxThru).concat(Array(18 - maxThru).fill(''))

    let scores = userScore.map((score) => MastersUtils.userHoleScore(score))
    return scores;
  }

  function displayPlayersForUser (user) {
    let roundNumber = MastersUtils.getRoundNumber(masters)
    let playerElements = user.playersSorted.map((player) => {
      return (
        <tr id={user.lastName + "-" + player.id + "-row"} className={user.lastName + "-user-row user-player-row hidden"}>
          <td className={`masters-table-data-name player-name ${ player.id == user.golfers.wildcard ? 'wildcard' : '' }` }>{ player.pos } { player.display_name.toUpperCase() } <div className="player-tier-label">{ MastersUtils.playerTierLabel(user, player) }</div></td>
          { MastersUtils.playerRoundScores(player, roundNumber, masters.pars.round1) }
          { MastersUtils.playerGrossScore(player) }
          { MastersUtils.playerNetScore(player, roundNumber) }
        </tr>
      )
    });
    return playerElements;
  }

  function displayUserTableRows () {
    if (MastersUtils.tournamentStarted()) {
      let sortedUsers = MastersUtils.sortUsersAndPlayers(users, masters);

      let userAndPlayerElements = sortedUsers.map((user, place) => displayUserAndPlayers(user, place) )

      return userAndPlayerElements;
    } else {
    }
  }

  function displayHelperText () {
    if (MastersUtils.tournamentStarted()) {
      if (MastersUtils.tournamentEnded(masters)) {
        return (
          <></>
        );
      } else {
        return (
          <div>
            <p className="masters-type-paragraph large-screen-only">Leaderboard extends through the hole of your furthest along top-5 player and shows your gross score (though bonuses are not shown, users are ranked by net score with bonuses).</p>
            <p className="masters-type-paragraph small-screen-only">Gross score shows sum of your top 5 players.</p>
            <p className="masters-type-paragraph-small small-screen-only">Net score factors in bonus points.</p>
            <p className="masters-type-paragraph-small small-screen-only">1st Place: -3</p>
            <p className="masters-type-paragraph-small small-screen-only">Top-5: -2</p>
            <p className="masters-type-paragraph-small small-screen-only">Top-10: -1</p>
            <p className="masters-type-paragraph-small small-screen-only">WC: x1.5 (under par only)</p>
            <p className="masters-type-paragraph-small small-screen-only">Scottie Scheffler: +2</p>
          </div>
        );
      }
    } else {
      return (
        <p className="masters-type-paragraph">Leaderboard will update once the tournament has begun.</p>
      );
    }
  }

  if (leaderboardReady == false) {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Masters">
        <NavBarMini alwaysShown={true} />
        <div className="masters-content">
          <div className="masters-header">
            <div className="masters-header-top">
              <p className={ "masters-title-standard" }>{currentYear} 1A Masters Pool</p>
              <a className="masters-type" href="/masters/augusta">Leaders At Augusta</a>
              { displayHelperText() }
            </div>
          </div>


          <div className="masters-body">
            <div className="masters-table-container">
              <div className="cut-off-half"><div className="masters-table-heading-title"><span>LEADERS</span></div></div>

              <table className="table masters-table">
                <thead className="masters-table-heading-hole">
                  <tr>
                    <th className="masters-table-heading-name"><span>HOLE</span></th>
                    <th className="masters-table-heading hole"><span>1</span></th>
                    <th className="masters-table-heading hole"><span>2</span></th>
                    <th className="masters-table-heading hole"><span>3</span></th>
                    <th className="masters-table-heading hole"><span>4</span></th>
                    <th className="masters-table-heading hole"><span>5</span></th>
                    <th className="masters-table-heading hole"><span>6</span></th>
                    <th className="masters-table-heading hole"><span>7</span></th>
                    <th className="masters-table-heading hole"><span>8</span></th>
                    <th className="masters-table-heading hole"><span>9</span></th>
                    <th className="masters-table-heading hole"><span>10</span></th>
                    <th className="masters-table-heading hole"><span>11</span></th>
                    <th className="masters-table-heading hole"><span>12</span></th>
                    <th className="masters-table-heading hole"><span>13</span></th>
                    <th className="masters-table-heading hole"><span>14</span></th>
                    <th className="masters-table-heading hole"><span>15</span></th>
                    <th className="masters-table-heading hole"><span>16</span></th>
                    <th className="masters-table-heading hole"><span>17</span></th>
                    <th className="masters-table-heading hole"><span>18</span></th>
                  </tr>
                </thead>
                <thead className="masters-table-heading-par">
                <tr>
                  <th className="masters-table-heading-name"><span>PAR</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>5</span></th>
                  <th className="masters-table-heading hole"><span>3</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                  <th className="masters-table-heading hole"><span>4</span></th>
                </tr>
                </thead>
                <thead className="masters-table-heading-total">
                  <tr>
                    <th className="masters-table-heading-name"><span>NAME</span></th>
                    <th className="masters-table-heading-small"><span>GROSS</span></th>
                    <th className="masters-table-heading-small"><span>NET</span></th>
                  </tr>
                </thead>
                <tbody>
                  { displayUserTableRows() }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default MastersRefactor;
