import React from 'react';
import Footer from './Footer.js';
import NavBar from './NavBar.js';
import NavBarMini from './NavBarMini.js';
import MainContent from './MainContent.js';

const FrontPage = () => {
  return (
    <div className="FrontPage">
      <NavBar />
      <NavBarMini />
      <MainContent />
      <Footer />
    </div>
  );
}

export default FrontPage
