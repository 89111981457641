import React from 'react';

function LoadingAnimation() {
  return (
    <div className="pulse-container">
      <div className="pulse"></div>
    </div>
  );
}

export default LoadingAnimation;

// import { useRef, useEffect } from 'react';
//
// function LoadingAnimation() {
//   const canvasRef = useRef(null);
//   const radius = 15;
//   const lineWidth = 7;
//   const colors = ['#cccccc', '#999999', '#666666', '#333333', '#000000', '#333333', '#666666', '#999999'];
//   let currentColor = 0;
//   let rotation = 0;
//   let startAngle = 0;
//   let endAngle = Math.PI / 2;
//   let counterClockwise = false;
//
//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');
//
//     // Set up animation loop
//     function draw() {
//       // Clear canvas
//       context.clearRect(0, 0, canvas.width, canvas.height);
//
//       // Set up styles
//       context.lineWidth = lineWidth;
//       context.strokeStyle = colors[currentColor];
//       context.lineCap = 'round';
//
//       // Draw arc
//       context.beginPath();
//       context.arc(canvas.width / 2, canvas.height / 2, radius, startAngle, endAngle, counterClockwise);
//       context.stroke();
//
//       // Update angles and colors
//       startAngle += 0.1;
//       endAngle += 0.1;
//       if (endAngle > 2 * Math.PI) {
//         endAngle = endAngle - 2 * Math.PI;
//         startAngle = startAngle - 2 * Math.PI;
//         currentColor++;
//         if (currentColor >= colors.length) {
//           currentColor = 0;
//         }
//       }
//
//       // Update rotation
//       rotation += 0.05;
//       canvas.style.transform = `rotate(${rotation}deg)`;
//
//       // Loop animation
//       requestAnimationFrame(draw);
//     }
//
//     // Start animation loop
//     draw();
//   }, [canvasRef]);
//
//   return (
//     <canvas ref={canvasRef} width={200} height={200} />
//   );
// }
//
// export default LoadingAnimation;
