import React, { Component, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import Article from './Article.js';

import { renderHeaderTop }  from './ArticleUtils.js';

import { collection, query, where, getDocs, setDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';

const setDefaultArticle = () => {
  let timestamp = new Timestamp(Date.now() / 1000, 0)

  return {
    title: 'This is my title',
    sutitle: '',
    article_type: '',
    content: [],
    date_published: timestamp,
    image_credit: '',
    image_desc: '',
    image_link: '',
    status: 'draft',
    style: 'skinny',
    author_size: 'sm',
    type: 'editorial',
    type_secondary: '',
    categories: [],
    tags: [],
  };
}

const setAuthorByUser = () => {
  return {
    bio: '',
    image_url: '',
    name: '',
    profile_url: ''
  }
}

const ArticleNew = () => {
  const [article, setArticle] = useState(setDefaultArticle);
  const [author, setAuthor] = useState(setAuthorByUser);
  // const [datePublished, setDatePublished] = useState("");

  useEffect(() => {
  }, [])

  return (
    <div className="ArticleNew">
      <NavBarMini alwaysShown={true} />
      <div className="article-content">
          <div className="article-header">
            { renderHeaderTop(article, 'edit') }
            {/* { renderHeaderBottom() } */}
          </div>

          <div className="article-body">
            { article.content?.map((paragraph, index) => {
            return (
              <div key={"paragraph-" + index} className="article-paragraph">
                <p>{ paragraph }</p>
              </div>
            )})}
          </div>

        </div>
    </div>
  );
}

export default ArticleNew;
