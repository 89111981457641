import React, { Component, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const auth = getAuth();
const user = auth.currentUser

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return (
      <div className="Account">
        <NavBarMini alwaysShown={true} page={'Account'} />

        <div className="account-content">
          <div id="account-header" className="account-section">
            <h1>Good evening.</h1>
            <p>You've supported independent journalism since { '2023' }.</p>
          </div>
          <div id="account-details" className="account-section">
            <hr></hr>
            <h1>Your profile</h1>
            <div className='account-detail'>
              <label>Display name</label>
              <p>{ user.displayName } </p>
            </div>
            <div className='account-detail'>
              <label>Email address</label>
              <p>{ user.email } </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
